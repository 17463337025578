import React from "react";

import { IconType } from "../index.types";

const Presentation = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 71 66"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M0 3.626A3.626 3.626 0 013.626 0h63.263a3.626 3.626 0 110 7.251h-.208v33.592a4.75 4.75 0 01-4.75 4.75H36.008V52.2l12.241 12.241-1.06 1.06-11.181-11.18v7.775h-1.5V54.32L23.327 65.504l-1.06-1.061L34.506 52.2v-6.607H8.585a4.75 4.75 0 01-4.75-4.75V7.25h-.208A3.626 3.626 0 010 3.626zM65.181 7.25H5.334v33.592a3.25 3.25 0 003.25 3.25h53.347a3.25 3.25 0 003.25-3.25V7.25zm1.708-1.5H3.626a2.126 2.126 0 010-4.251h63.263a2.126 2.126 0 010 4.251z"
      clipRule="evenodd"
    ></path>
  </svg>
);

Presentation.propTypes = IconType;

export default Presentation;
