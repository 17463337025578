import React from "react";

import { IconType } from "../index.types";

const Pen = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 93 60"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M82.57.375a.75.75 0 00-1.025-.274l-4.547 2.625-.625-1.083a.75.75 0 00-1.025-.275l-3.464 2a.75.75 0 00-.274 1.025l.625 1.082L14.86 38.601a.746.746 0 00-.245.228L.144 58.719a.75.75 0 00.713 1.184l23.97-3.454a.741.741 0 00.296-.102l57.362-33.118.625 1.082a.75.75 0 001.024.275l3.464-2a.75.75 0 00.275-1.025l-.625-1.082 4.547-2.625a.75.75 0 00.274-1.024L82.57.375zm3.01 20.213l-.006-.008-9.5-16.455a.351.351 0 01-.005-.009l-.62-1.074-2.165 1.25 10.75 18.62 2.165-1.25-.62-1.074zm.918-1.408l3.897-2.25-8.75-15.155-3.897 2.25 8.75 15.155zm-4.764 2.75L25.01 54.68l-8.75-15.155 56.724-32.75 8.75 15.155zM23.532 55.12l-8.37-14.495-7.724 10.618 3.302 5.72 12.792-1.843zM9.14 57.194l-2.67-4.623-4.069 5.594 6.739-.971z"
      clipRule="evenodd"
    ></path>
  </svg>
);

Pen.propTypes = IconType;

export default Pen;
