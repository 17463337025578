import DesignSystem from "design-system-utils";

import { BREAKPOINTS } from "../constants/breakpoints";
import colorPalette from "./colorPalette";

const tokens = {
  type: {
    fontFamily: {
      primary: "Bunch, sans-serif",
      secondary: "Inter, sans-serif",
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
      regular: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
  },
  colors: {
    colorPalette,
    brand: {
      primary: "#E65140",
      primaryLight: "#FBCCC6",
      secondary: "#A8E0FF",
      black: "#000000",
      white: "#ffffff",
      TdHWhite: "#F9F9F9",
      TdHPink: "#E9B4BB",
      TdHOrange: "#DD9366",
      TdHRed: "#E65140",
      TdHLightBrown: "#825B43",
      TdHDarkBrown: "#4B291E",
      TdHBlack: "#232231",
    },
  },
  sizes: [0.8, 1, 1.5, 2, 2.5, 4.5, 6],
  shadows: {
    1: "0 10px 20px 0 rgba(0, 0, 0, 0.03), 0 15px 15px -10px rgba(0, 0, 0, 0.11), 0 20px 25px 10px rgba(0, 0, 0, 0.06)",
  },
  breakpoints: {
    xs: `${BREAKPOINTS.XS}px`,
    sm: `${BREAKPOINTS.SM}px`,
    md: `${BREAKPOINTS.MD}px`,
    mdlg: `${BREAKPOINTS.MDLG}px`,
    lg: `${BREAKPOINTS.LG}px`,
    xl: `${BREAKPOINTS.XL}px`,
  },
  layers: {
    menu: 100,
  },
};

export default new DesignSystem(tokens);
