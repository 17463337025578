import React from "react";

import { DownloadLinkType } from "../index.types";

const DownloadLink = ({ download, to, children, className, ...other }) => (
  <a
    download={download}
    rel="noopener noreferrer"
    href={to}
    className={className}
    {...other}
  >
    {children}
  </a>
);

DownloadLink.propTypes = DownloadLinkType;

export default DownloadLink;
