import React from "react";

import { IconType } from "../index.types";

const Mail = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 20 15" {...props}>
    <path
      fill={color}
      d="M10,10c.91,0,2.21-1.14,2.87-1.62,5.18-3.76,5.58-4.09,6.77-5A.93.93,0,0,0,20,2.62V1.87A1.88,1.88,0,0,0,18.12,0H1.88A1.88,1.88,0,0,0,0,1.87v.75a.93.93,0,0,0,.36.74c1.19.93,1.59,1.26,6.77,5C7.79,8.86,9.09,10,10,10Zm8.12,5A1.87,1.87,0,0,0,20,13.14V5.23a.24.24,0,0,0-.38-.18c-.87.67-2,1.53-6,4.4-.8.57-2.22,1.85-3.61,1.84S7.22,10,6.4,9.45c-4-2.87-5.15-3.72-6-4.4A.24.24,0,0,0,0,5.24v7.9A1.87,1.87,0,0,0,1.88,15Z"
    />
  </svg>
);

Mail.propTypes = IconType;

export default Mail;
