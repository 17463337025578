import React from "react";

import { IconType } from "../index.types";

const VideoFile = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 53 39" fill="none" {...props}>
    <rect
      x="0.75"
      y="0.75"
      width="51.1758"
      height="37.4656"
      rx="3.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M0.72168 7.21582H52.6759M1.08247 32.4713H52.6759"
      stroke={color}
      strokeWidth="1.5"
    />
    <path d="M10.1021 7.21595V0.72168" stroke={color} strokeWidth="1.5" />
    <path d="M10.1021 38.9655V32.4712" stroke={color} strokeWidth="1.5" />
    <path d="M20.9258 7.21595V0.72168" stroke={color} strokeWidth="1.5" />
    <path d="M20.9258 38.9655V32.4712" stroke={color} strokeWidth="1.5" />
    <path d="M31.75 7.21595V0.72168" stroke={color} strokeWidth="1.5" />
    <path d="M31.75 38.9655V32.4712" stroke={color} strokeWidth="1.5" />
    <path d="M42.5737 7.21595V0.72168" stroke={color} strokeWidth="1.5" />
    <path d="M42.5737 38.9655V32.4712" stroke={color} strokeWidth="1.5" />
    <path
      d="M30.0948 20.9023L23.41 25.0974C22.5775 25.6198 21.4955 25.0215 21.4955 24.0386L21.4955 15.6485C21.4955 14.6656 22.5775 14.0673 23.41 14.5897L30.0948 18.7848C30.8755 19.2747 30.8755 20.4124 30.0948 20.9023Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

VideoFile.propTypes = IconType;

export default VideoFile;
