import Link from "@components/atoms/Link";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import chunkArray from "@utils/chunkArray";
import { arrayOf, string } from "prop-types";
import React from "react";

import { navItemType } from "../../../__types__";

const StyledSubNav = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
`;

const NavColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: span 2;
`;

const NavItem = styled(Link)(
  ({ theme }) => css`
    color: ${theme.brand("TdHWhite")};
    text-decoration: none;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover,
    &:focus {
      color: ${theme.brand("primary")};
    }
  `,
);

const SubNav = ({ navItems, className }) => {
  const cols = chunkArray(5, navItems);
  return (
    <StyledSubNav className={className}>
      {cols.map((col, i) => (
        <NavColumn key={i}>
          {col.map((item) => (
            <NavItem key={item.path} to={item.path}>
              {item.label}
            </NavItem>
          ))}
        </NavColumn>
      ))}
    </StyledSubNav>
  );
};

SubNav.propTypes = {
  navItems: arrayOf(navItemType),
  className: string,
};

export default SubNav;
