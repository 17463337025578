const RECORD_TYPE = {
  PROGRAM: "datoCmsProgramBlock",
  PEOPLE: "datoCmsPeopleBlock",
  ACCORDION: "datoCmsAccordionBlock",
  COPY: "datoCmsCopyBlock",
  NEWS: "datoCmsNewsBlock",
  TAGNEWS: "datoCmsTagNewsBlock",
  SECTION: "datoCmsSectionBlock",
  VIDEO: "datoCmsVideoBlock",
  FULL_IMAGE: "datoCmsFullImageBlock",
  QUOTE: "datoCmsQuoteBlock",
  RICH_CONTENT: "datoCmsRichContentBlock",
  PARTNER: "datoCmsPartnerBlock",
  ARTICLE: "datoCmsArticle",
  PUBLICATION: "datoCmsPublication",
  MAGLR: "datoCmsMaglr",
  CONTACT_SECTION: "datoCmsContactSection",
  SINGLE_CTA: "datoCmsSingleCtaBlock",
  CTA_BLOCK_GRID: "datoCmsCtaBlockGrid",
  CAMPAIGN: "datoCmsMaglrCampaignPage",
};
const RECORD_TYPE_LEGACY = {
  PROGRAM: "DatoCmsProgramBlock",
  PEOPLE: "DatoCmsPeopleBlock",
  ACCORDION: "DatoCmsAccordionBlock",
  COPY: "DatoCmsCopyBlock",
  NEWS: "DatoCmsNewsBlock",
  TAGNEWS: "DatoCmsTagNewsBlock",
  SECTION: "DatoCmsSectionBlock",
  VIDEO: "DatoCmsVideoBlock",
  FULL_IMAGE: "DatoCmsFullImageBlock",
  QUOTE: "DatoCmsQuoteBlock",
  RICH_CONTENT: "DatoCmsRichContentBlock",
  PARTNER: "DatoCmsPartnerBlock",
  ARTICLE: "DatoCmsArticle",
  PUBLICATION: "DatoCmsPublication",
  MAGLR: "DatoCmsMaglr",
  CONTACT_SECTION: "DatoCmsContactSection",
  SINGLE_CTA: "DatoCmsSingleCtaBlock",
  CTA_BLOCK_GRID: "DatoCmsCtaBlockGrid",
  CAMPAIGN: "DatoCmsMaglrCampaignPage",
};

module.exports = {
  RECORD_TYPE,
  RECORD_TYPE_LEGACY,
};
