import { css } from "@emotion/react";
import styled from "@emotion/styled";
import formatBunch from "@utils/formatBunch";
import PropTypes from "prop-types";
import React from "react";

const formatChildren = (children) => {
  return React.Children.map(children, (child) => {
    // If the child is a string, format it
    if (typeof child === "string") {
      return formatBunch(child);
    }
    // If the child has its own children, recursively format them
    if (child.props && child.props.children) {
      return React.cloneElement(child, {
        ...child.props,
        children: formatChildren(child.props.children),
      });
    }
    // Otherwise, just return the child as is
    return child;
  });
};

const withFormatBunch = (WrappedComponent) => {
  const WithFormatBunch = ({ children, ...props }) => {
    let newChildren;
    if (typeof children === "string") {
      newChildren = { __html: formatBunch(children) };
    } else {
      newChildren = formatChildren(children);
    }

    return (
      <WrappedComponent {...props} dangerouslySetInnerHTML={newChildren}>
        {typeof children === "string" ? null : newChildren}
      </WrappedComponent>
    );
  };

  WithFormatBunch.displayName = `WithFormatBunch(${getDisplayName(
    WrappedComponent
  )})`;

  WithFormatBunch.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.string,
    ]),
    dangerouslySetInnerHTML: PropTypes.shape({
      __html: PropTypes.string,
    }),
  };

  return WithFormatBunch;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

const defaults = (theme) => css`
  margin: 0;
  font-family: ${theme.get("type.fontFamily.primary")};
  font-weight: ${theme.get("type.fontWeight.black")};

  .ss01 {
    font-feature-settings: "ss01" 1;
  }
  .ss02 {
    font-feature-settings: "ss02" 1;
  }
`;

const secondary = (theme) => css`
  margin: 0;
  font-family: ${theme.get("type.fontFamily.secondary")};
`;

const Header1 = withFormatBunch(
  styled.h1(
    ({ theme, primary }) => css`
      ${defaults(theme)};
      font-size: 3rem;
      line-height: 3rem;
      color: ${theme.brand(primary ? "TdHRed" : "TdHBlack")};
      @media (min-width: ${theme.get("breakpoints.md")}) {
        font-size: 4.5rem;
        line-height: 3.75rem;
      }
    `
  )
);

Header1.propTypes = {
  primary: PropTypes.bool,
};

const Header2 = withFormatBunch(
  styled.h2(
    ({ theme, white }) => css`
      ${defaults(theme)};
      font-size: 2rem;
      line-height: 2.25rem;
      color: ${theme.brand(white ? "TdHWhite" : "TdHBlack")};
      @media (min-width: ${theme.get("breakpoints.md")}) {
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
    `
  )
);

Header2.propTypes = {
  white: PropTypes.bool,
};

const Header3 = withFormatBunch(
  styled.h3(
    ({ theme, white }) => css`
      ${defaults(theme)};
      font-size: 2rem;
      line-height: 2.25rem;
      color: ${theme.brand(white ? "TdHWhite" : "TdHBlack")};
      @media (min-width: ${theme.get("breakpoints.md")}) {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
    `
  )
);

Header3.propTypes = {
  white: PropTypes.bool,
};

const Header4 = styled.h4(
  ({ theme, primary, white }) => css`
    ${secondary(theme)};
    font-size: 1.5rem;
    font-weight: ${theme.get("type.fontWeight.black")};
    line-height: 1.5rem;
    color: ${primary || white
      ? theme.brand(white ? "TdHWhite" : "TdHRed")
      : "inherit"};
  `
);

Header4.propTypes = {
  white: PropTypes.bool,
  primary: PropTypes.bool,
};

const Header5 = styled.h5(
  ({ theme, primary, white }) => css`
    ${secondary(theme)};
    font-size: 1.125rem;
    font-weight: ${theme.get("type.fontWeight.black")};
    line-height: 1.25rem;
    color: ${primary || white
      ? theme.brand(white ? "TdHWhite" : "TdHRed")
      : theme.brand("TdHBlack")};
  `
);

Header5.propTypes = {
  white: PropTypes.bool,
  primary: PropTypes.bool,
};

export { Header1, Header2, Header3, Header4, Header5, withFormatBunch };
