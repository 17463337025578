import styled from "@emotion/styled";
import { node } from "prop-types";
import React from "react";

const StyledLayout = styled.div`
  padding-top: 64px;
  @media (min-width: ${({ theme }) => theme.get("breakpoints.lg")}) {
    padding-top: 83px;
  }
`;

const Layout = ({ children }) => <StyledLayout>{children}</StyledLayout>;

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
