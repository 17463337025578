/**
 * Calculates size in px based on factor
 * @param {number} size
 * @param {number} factor
 * @returns {string}
 */
const calcSize = (size, factor = 16) => {
  if (typeof size !== "number" || typeof factor !== "number") {
    return "16px";
  }
  return `${size * factor}px`;
};

export default calcSize;
