import React from "react";

import { IconType } from "../index.types";

const PdfFile = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 31 39" fill="none" {...props}>
    <path
      d="M26.7273 38.25H4C2.20507 38.25 0.75 36.7949 0.75 35V4C0.75 2.20507 2.20507 0.75 4 0.75H18.9909C19.8692 0.75 20.7101 1.10547 21.3221 1.73545L29.0584 9.69935C29.6477 10.3059 29.9773 11.1183 29.9773 11.9639V35C29.9773 36.7949 28.5222 38.25 26.7273 38.25Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M20.0908 0.59082V6.93173C20.0908 9.14087 21.8817 10.9317 24.0908 10.9317H30.1363"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M15.3638 26.5913L15.3638 13.5913"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.3638 13.5913L9.45468 20.0913"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.3638 13.5913L21.2729 20.0913"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.09082 31.4658H23.6363"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

PdfFile.propTypes = IconType;

export default PdfFile;
