const sitemap = {
  home: {
    nl: {
      label: "Home",
      path: "",
    },
    en: {
      label: "Home",
      path: "",
    },
  },

  about_mission: {
    nl: {
      label: "Onze missie",
      path: "onze-missie",
    },
    en: {
      label: "Our mission",
      path: "our-mission",
    },
  },

  join_fight: {
    nl: {
      label: "Strijd mee",
      path: "strijd-mee",
    },
    en: {
      label: "Fight with us",
      path: "join-the-fight",
    },
  },

  about_stores: {
    nl: {
      label: "Winkels",
      path: "onze-winkels",
    },
    en: {
      label: "Shops",
      path: "our-stores",
    },
  },

  projects: {
    nl: {
      label: "Projecten",
      path: "projecten",
    },
    en: {
      label: "Projects",
      path: "projects",
    },
  },

  about_what_we_do: {
    nl: {
      label: "Ons werk",
      path: "wat-we-doen",
    },
    en: {
      label: "Our work",
      path: "what-we-do",
    },
  },

  partners: {
    nl: {
      label: "Als partner",
      path: "partners",
    },
    en: {
      label: "As a partner",
      path: "partners",
    },
  },

  cookies: {
    nl: {
      label: "Cookies",
      path: "cookies",
    },
    en: {
      label: "Cookies",
      path: "cookies",
    },
  },

  privacy: {
    nl: {
      label: "Privacy",
      path: "privacy",
    },
    en: {
      label: "Privacy",
      path: "privacy",
    },
  },

  currently: {
    nl: {
      label: "Actueel",
      path: "actueel",
    },
    en: {
      label: "Latest",
      path: "latest",
    },
  },

  news: {
    nl: {
      label: "Nieuws",
      path: "actueel",
    },
    en: {
      label: "News",
      path: "latest",
    },
  },

  publications: {
    nl: {
      label: "Publicaties",
      path: "publicaties",
    },
    en: {
      label: "Publications",
      path: "publications",
    },
  },

  factsheets: {
    nl: {
      label: "Factsheets",
      path: "factsheets",
    },
    en: {
      label: "Factsheets",
      path: "factsheets",
    },
  },

  our_organisation: {
    nl: {
      label: "Organisatie",
      path: "onze-organisatie",
    },
    en: {
      label: "Our organisation",
      path: "our-organisation",
    },
  },

  regions: {
    nl: {
      label: "Regio's",
      path: "regios",
    },
    en: {
      label: "Regions",
      path: "regions",
    },
  },

  countries: {
    nl: {
      label: "Waar we werken",
      path: "landen",
    },
    en: {
      label: "Where we work",
      path: "countries",
    },
  },

  contact: {
    nl: {
      label: "Contact",
      path: "contact",
    },
    en: {
      label: "Contact",
      path: "contact",
    },
  },

  jobs: {
    nl: {
      label: "Werken bij",
      path: "werken-bij",
    },
    en: {
      label: "Jobs",
      path: "jobs",
    },
  },

  integrity: {
    nl: {
      label: "Integriteit",
      path: "contact/integriteit",
    },
    en: {
      label: "Integrity",
      path: "contact/integrity",
    },
  },

  newsLetter: {
    nl: {
      label: "Nieuwsbrief",
      path: "nieuwsbrief",
    },
  },

  donate: {
    nl: {
      label: "Doneer",
      path: "https://secured.terredeshommes.nl/algemene_donatie?_ga:2.50385901.559439627.1589179996-332109269.1574154922",
    },
    en: {
      label: "Donate",
      path: "https://secured.terredeshommes.nl/donation",
    },
  },

  become_donor: {
    nl: {
      label: "Word donateur",
      path: "https://secured.terredeshommes.nl/algemene_donatie",
    },
    en: {
      label: "Become a donor",
      path: "https://secured.terredeshommes.nl/donation?_ga:2.171202464.1610801280.1639405468-891253147.1637222903",
    },
  },

  collaboration: {
    nl: {
      label: "",
      path: "samenwerken",
    },
    en: {
      label: "",
      path: "collaborate",
    },
  },

  advertisement: {
    nl: {
      label: "",
      path: "adverteren",
    },
    en: {
      label: "",
      path: "advertise",
    },
  },

  search: {
    nl: {
      label: "Zoeken",
      path: "zoeken",
    },
    en: {
      label: "Search",
      path: "search",
    },
  },

  petition: {
    nl: {
      label: "Teken de petitie",
      path: "strijd-mee/teken-onze-petities",
    },
  },

  donate_goods: {
    nl: {
      label: "Geef spullen",
      path: "onze-winkels",
    },
    en: {
      label: "Donate your goods",
      path: "our-stores",
    },
  },

  results: {
    nl: {
      label: "Jaarverslagen",
      path: "jaarverslagen",
    },
    en: {
      label: "Annual reports",
      path: "annual-reports",
    },
  },

  become_volunteer: {
    nl: {
      label: "Word vrijwilliger",
      path: "vrijwilligers",
    },
    en: {
      label: "Become a volunteer",
      path: "volunteers",
    },
  },

  history: {
    nl: {
      label: "Geschiedenis",
      path: "onze-geschiedenis",
    },
    en: {
      label: "Our History",
      path: "our-history",
    },
  },

  ambassadors: {
    nl: {
      label: "Ambassadeurs",
      path: "onze-organisatie/ambassadeurs-van-terre-des-hommes",
    },
    en: {
      label: "Ambassadors",
      path: "our-organisation/ambassadors-of-terre-des-hommes",
    },
  },

  update_data: {
    nl: {
      label: "Gegevens wijzigen",
      path: "contact",
    },
    en: {
      label: "Change your info",
      path: "contact",
    },
  },

  donor_service: {
    nl: {
      label: "Donateursservice",
      path: "contact",
    },
  },

  faq: {
    nl: {
      label: "Veelgestelde vragen",
      path: "veelgestelde-vragen",
    },
    en: {
      label: "FAQ",
      path: "faq",
    },
  },

  press: {
    nl: {
      label: "Pers",
      path: "pers",
    },
    en: {
      label: "Press",
      path: "press",
    },
  },
};

export default sitemap;
