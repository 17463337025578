import Link from "@components/atoms/Link";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { arrayOf } from "prop-types";
import React from "react";

import { navItemType } from "../../../__types__";

const StyledMainNav = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (min-width: ${({ theme }) => theme.get("breakpoints.md")}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  @media (min-width: ${({ theme }) => theme.get("breakpoints.md")}) {
    width: auto;
  }
`;

const NavItem = styled(Link)(
  ({ theme }) => css`
    font-weight: 600;
    text-decoration: none;
    color: ${theme.brand("TdHWhite")};
    &:hover,
    &:focus {
      color: ${theme.brand("TdHRed")};
    }
  `,
);

const PrimaryNavItem = styled(NavItem)`
  color: ${({ theme }) => theme.brand("TdHRed")};
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.brand("TdHWhite")};
  }
`;

const MainNav = ({ navItems, callToActions }) => (
  <StyledMainNav>
    <Column>
      {navItems.map(({ path, label }) => (
        <NavItem key={path} to={path}>
          {label}
        </NavItem>
      ))}
    </Column>
    <Column>
      {callToActions.map(({ path, label, trackEvent }) => (
        <PrimaryNavItem
          key={path}
          to={path}
          onClick={() => {
            trackEvent && trackEvent("footer");
          }}
        >
          {label}
        </PrimaryNavItem>
      ))}
    </Column>
  </StyledMainNav>
);

MainNav.propTypes = {
  navItems: arrayOf(navItemType),
  callToActions: arrayOf(navItemType),
};

export default MainNav;
