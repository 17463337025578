import Icon from "@components/atoms/Icon";
import SelectLocale from "@components/molecules/SelectLocale";
import { LOCALE } from "@constants";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { arrayOf, bool, func, object, oneOf, shape } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { navItemType } from "../../../__types__";

const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.brand("TdHWhite")};
    transition: all 0.2s ease-out;
    overflow: hidden;
    width: 100%;
    z-index: ${theme.get("layers.menu")};
    @media (min-width: ${theme.get("breakpoints.lg")}) {
      position: fixed;
    }
  `,
);

const Navigation = styled.div(
  ({ theme }) => css`
    max-width: ${theme.get("breakpoints.lg")};
    width: 100%;
    margin: 0 auto;
    padding: 3rem 1rem 4rem 1rem;
    display: flex;
    flex-wrap: wrap;
  `,
);

const Category = styled.div(
  ({ theme }) => css`
    width: 100%;
    padding: 0 0 3rem 0;
    color: ${theme.brand("TdHBlack")};
    @media (min-width: ${theme.get("breakpoints.lg")}) {
      width: calc(100% / 3);
      padding: 0 2rem 3rem 2rem;
    }
  `,
);

const CategoryLabel = styled(Link)(
  ({ theme }) => css`
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    transition: color 0.2s ease-out;
    &:hover {
      color: ${theme.brand("TdHRed")};
    }
    h4 {
      font-family: ${theme.get("type.fontFamily.secondary")};
      font-size: 1.125rem;
      font-weight: 900;
      margin: 0 0.5rem 0;
    }
  `,
);

const NavItem = styled(Link)(
  ({ theme }) => css`
    font-family: ${theme.get("type.fontFamily.secondary")};
    font-weight: 600;
    font-size: 1rem;
    display: block;
    margin: 0.8rem 0 0.8rem 2rem;
    color: ${theme.brand("TdHBlack")};
    text-decoration: none;
    transition: color 0.2s ease-out;
    &:hover {
      color: ${theme.brand("TdHRed")};
    }
  `,
);

const StyledSelectLocale = styled(SelectLocale)(
  ({ theme }) => css`
    margin-left: 0.5rem;
    font-family: ${theme.get("type.fontFamily.secondary")};
    font-size: 1.125rem;
    font-weight: 900;
    @media (min-width: ${theme.get("breakpoints.lg")}) {
      margin-left: 2.5rem;
    }
  `,
);

const Menu = ({
  menuItems,
  show,
  locale,
  localeOptions,
  toggleMenu,
  onLocaleChange,
}) => {
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef();

  const handleSetMenuHeight = () => {
    if (menuRef.current) {
      setMenuHeight(menuRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    handleSetMenuHeight();

    window.addEventListener("resize", handleSetMenuHeight);
    return () => {
      window.removeEventListener("resize", handleSetMenuHeight);
    };
  }, [menuRef]);

  return (
    <Container style={{ height: show ? `${menuHeight}px` : 0 }}>
      <Navigation ref={menuRef}>
        {menuItems.map((category) => (
          <Category key={category.label}>
            <CategoryLabel to={category.path} onClick={() => toggleMenu(false)}>
              <h4>{category.label}</h4>
              <Icon type="arrow" />
            </CategoryLabel>
            {category.items.map((item) => (
              <NavItem
                key={item.path}
                to={item.path}
                onClick={() => toggleMenu(false)}
              >
                {item.label}
              </NavItem>
            ))}
          </Category>
        ))}
        <StyledSelectLocale
          activeLocale={locale}
          onLocaleChange={onLocaleChange}
          options={localeOptions}
          toggleMenu={toggleMenu}
        />
      </Navigation>
    </Container>
  );
};

Menu.propTypes = {
  menuItems: arrayOf(
    shape({
      navItemType,
      items: arrayOf(navItemType),
    }),
  ),
  show: bool,
  locale: oneOf(Object.values(LOCALE)),
  localeOptions: arrayOf(object),
  onLocaleChange: func,
  toggleMenu: func,
};

export default Menu;
