import React from "react";

import { IconType } from "../index.types";

const Menu = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
    <g fill={color}>
      <rect width="25" height="5" />
      <rect y="10" width="25" height="5" />
      <rect y="20" width="25" height="5" />
    </g>
  </svg>
);

Menu.propTypes = IconType;

export default Menu;
