import { string } from "prop-types";
import React from "react";

const PaintPalette = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.7 708.7" {...props}>
    <path
      fill={color}
      d="M668.9,275.3c-12.6-58.6-43.7-117-79.8-153.3C535.5,68.1,472.5,41.6,410,35.7c-10.4-1-20.8-1.4-31.1-1.4
	s-20.6,0.8-30.8,1.9c-81.4,9.2-156.6,49.1-209.7,102.2c-53.1,53-93,128.3-102.2,209.7S50,517.6,121.9,589.2
	c36.3,36.1,94.8,67.2,153.3,79.8c29.3,6.3,58.7,7.9,85.9,0.7c27.1-7.2,52.1-25,65.4-53.1c17.8-37.5,8.5-77,4.2-109.7
	c-1-7.6-1.8-14.9-2.3-21.7l-28.9-17.5c-46.2-32.8-22.5-35.1-57.6-44.1c-17.7-4.5-24.1-6.8-24.1-6.8c-16.3-6.1-42.1-22.2-47.5-50
	s0-55.9,0-55.9s1.2-7.9,4.6-5.9c0.9,0.5,1.9,1.6,3.1,3.6c5.9,10,13.8,23.2,38,22.2c24.3-1,32.9,0.5,44.7,13.5
	c0,0,15.2,14.4,17.4,33.5c1.4,12.7,3.8,27.5,13.4,29.3c21.5,4.1,41.2,20,41.2,20c0.8,0.8,4.4,4.2,9.9,9.2
	c23.3,21.6,79.6,73.2,86.6,79.9c6.6,6.2,23,23,30,34.5c4.8,1.9,7.9,2.5,8.2,0.9c1.5-8.4-24.5-35.2-33.2-43.4
	c-6.7-6.3-58.1-53.5-83.1-76.6c3.8-1.4,8.1-2.4,13.2-3c11.4-1.2,26.2,0,42.5,2.2c32.7,4.3,72.2,13.6,109.7-4.2
	c28.1-13.3,45.9-38.3,53.1-65.4C676.8,334.1,675.2,304.6,668.9,275.3z M120.6,387.8c-8.9-8.9-13.8-20.9-13.8-33.4s5-24.5,13.8-33.4
	c9-9,21.3-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4s-5,24.5-13.8,33.4c-8.9,8.9-20.9,13.8-33.4,13.8
	S129.5,396.6,120.6,387.8z M220.7,521.4c-8.9,8.9-20.9,13.8-33.4,13.8s-24.5-5-33.4-13.8c-8.9-8.9-13.8-20.9-13.8-33.4
	s5-24.5,13.8-33.4c9.1-9,21.4-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4S229.5,512.5,220.7,521.4z
	 M254.1,254.2c-8.9,8.9-20.9,13.8-33.4,13.8s-24.5-5-33.4-13.8c-8.9-8.9-13.8-20.9-13.8-33.4s5-24.5,13.8-33.4
	c9.1-9,21.4-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4S262.9,245.3,254.1,254.2z M320.9,120.6
	c9.1-9,21.4-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4s-5,24.5-13.8,33.4c-8.9,8.9-20.9,13.8-33.4,13.8
	s-24.5-5-33.4-13.8c-8.9-8.9-13.8-20.9-13.8-33.4S312.1,129.5,320.9,120.6z M487.9,234.7c-12.5,0-24.6-5-33.4-13.9
	c-8.9-8.9-13.8-20.9-13.8-33.4s5-24.5,13.8-33.4c9-9,21.4-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4
	s-5,24.5-13.8,33.4C512.4,229.7,500.4,234.7,487.9,234.7z M588.1,354.4c-8.9,8.9-20.9,13.8-33.4,13.8s-24.5-5-33.4-13.8
	c-8.9-8.9-13.8-20.9-13.8-33.4s5-24.5,13.8-33.4c9.1-9,21.4-14,34.1-13.8c12.3,0.2,24,5.1,32.7,13.8c8.9,8.9,13.8,20.9,13.8,33.4
	S596.9,345.5,588.1,354.4z"
    />
  </svg>
);

PaintPalette.propTypes = {
  color: string,
};

export default PaintPalette;
