const replacementMap = {
  B: "<span class='ss01'>B</span>",
  G: "<span class='ss01'>G</span>",
  K: "<span class='ss01'>K</span>",
  f: "<span class='ss01'>f</span>",
  a: "<span class='ss02'>a</span>",
};

/**
 * Replaces characters in a string with their corresponding Unicode characters
 * as specified in the replacementMap object.
 *
 * @param {string} string - The input string.
 * @returns {string} The string with characters replaced by their corresponding Unicode characters.
 *
 * @example
 * // returns "BaKed"
 * formatBunch("Baked")
 *
 * @example
 * // returns "Good \u0042oy"
 * formatBunch("Good Boy")
 */

const formatBunch = (string) => {
  const regex = new RegExp(Object.keys(replacementMap).join("|"), "g");
  return string.replace(regex, (match) => replacementMap[match]);
};

export default formatBunch;
