import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { forwardRef } from "react";

const HamburgerLine = styled.div`
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  display: block;
  transition: background 0.2s ease, transform 0.25s ease;
`;

const HamburgerWrapper = styled.div`
  font-weight: 600;
`;

const HamburgerIcon = styled.div(({ active, theme }) => {
  const activeStyles =
    active &&
    `
    ${HamburgerLine} {

      transform: scaleX(0);

      &:nth-of-type(1) {
        transform: translateX(-4px) translateY(-1px) rotate(-45deg);
      }

      &:nth-of-type(3) {
        transform: translateX(-4px) translateY(1px) rotate(45deg);
      }
    }

  `;

  return () => css`
    width: 24px;
    height: 24px;
    padding: 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    ${HamburgerLine} {
      background: ${theme.brand("TdHBlack")};
      &:nth-of-type(1) {
        align-self: flex-start;
        transform-origin: top right;
      }
      &:nth-of-type(3) {
        align-self: flex-end;
        transform-origin: bottom right;
      }
    }

    ${activeStyles}
  `;
});

const Hamburger = forwardRef(({ ...args }, ref) => (
  <HamburgerWrapper ref={ref} {...args}>
    <HamburgerIcon {...args}>
      <HamburgerLine />
      <HamburgerLine />
      <HamburgerLine />
    </HamburgerIcon>
  </HamburgerWrapper>
));

Hamburger.displayName = "Hamburger";
Hamburger.propTypes = {};

export default Hamburger;
