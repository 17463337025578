import { string } from "prop-types";
import React from "react";

const HandWithHeart = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.7 708.7" {...props}>
    <path
      fill={color}
      d="M622.9,527.6c-0.4,0.5-0.9,1-1.3,1.5c-8.3,8.8-18.9,14.3-29.3,19.6c-61.6,31.1-125.8,60.5-193.2,71.9
			c-21.6,3.7-43.9,2.1-64.9-4.3c-8.9-2.7-26.2-12.8-61.1-32.4c-19.1-10.7-26.4-15.1-39.5-19.3c-13-4.2-27.3-8.6-44.9-5.2
			c-11.8,2.3-21.3,7.4-28,12.1c-2.4,1.6-5.4-0.5-5.1-3.5c2.3-25.1,4.2-46.2,5.6-62.4c3.2-37.1,4.1-49.7,4.7-58.2
			c1.5-21.4,2.4-39.3,3-52.4c0.1-1.9,1.3-3.6,3-4.2c17.7-5.7,104.9-31.8,163.8-14.3c22.2,6.6,44.4,20.5,47.5,22.5
			c14,9.2,29.3,20.5,37.1,36.8c5.1,10.6,6.5,24,1.4,34.6c-1,2.2-2.4,4.2-4.3,5.4c-1.3,0.8-2.8,1.1-4.2,1.3c-7.9,1.1-15.8-1-23-4.5
			c-13.9-6.8-25.5-18.3-39.3-25.6c-4.2-2.2-23-13.6-27.4-7.5c-1.4,2-0.5,7.4,2.1,9.1c13.4,8.8,26.8,17.6,40.6,25.5
			c12.6,7.2,26.5,16.5,41,17.7c12.1,1,25-2.5,37-4.4c13.6-2.2,27.1-4.4,40.7-6.5c31.2-5,62.4-11,93.6-15.3c11.2-1.5,22.9-1.5,33.5,3
			c10.5,4.5,19.7,14.3,21.7,26.6C635.4,506.9,630.5,518.9,622.9,527.6z"
    />
    <path
      fill={color}
      d="M154.5,391.7c-5.4,65.6-10.1,124.4-14.5,177.5c-0.1,1.3-1.6,2.3-3.3,2c-16.5-2.4-33.5-4.6-51-6.6
			c-17.6-2-34.6-3.7-51-5.1c-1.5-0.1-2.8-1.2-2.8-2.5c0.2-54.7,0.4-115.6,0.7-183.6c0-2,2-3.4,4-2.8c16.2,4.5,34.6,8.8,55,12
			c21.8,3.5,41.8,5.2,59.7,5.8C153.2,388.5,154.6,390,154.5,391.7z"
    />
    <path
      fill={color}
      d="M676.7,113.4v184.9c0,15-12.3,27.3-27.3,27.3H487.9c-14.7,10.1-29.4,20.3-44,30.6c-5.8,4.1-11.3,8.7-17.1,12.8
		c-6.6,4.6-13.3,9.4-22.1,7.8c-6.7-1.2-9.2-3.9-9.5-10.7c-0.5-12.1-0.7-24.2-1-36.2c0-0.7,0-1.5,0-2.2c0-0.8,0-1.5,0-2.1h-37.5
		c-15,0-27.3-12.3-27.3-27.3V113.4c0-15,12.3-27.3,27.3-27.3h292.8C664.4,86.1,676.7,98.4,676.7,113.4z M541.5,130.2
		c-19.3,2.3-28.5,13-32.7,22.2c-4.3-9.2-13.4-19.9-32.7-22.3c-1.5-0.2-3.1-0.3-4.6-0.3c-48-0.9-92.4,74.1,37,151.9l0.4,0.3l0.7-0.4
		l0,0C642.6,201.3,591,124.1,541.5,130.2z"
    />
  </svg>
);

HandWithHeart.propTypes = {
  color: string,
};

export default HandWithHeart;
