import React from "react";

import { IconType } from "../index.types";

const CircleMinus = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 25.5" {...props}>
    <path
      fill={color}
      d="M12.75,25.5A12.75,12.75,0,1,1,25.5,12.75,12.76,12.76,0,0,1,12.75,25.5Zm0-24A11.25,11.25,0,1,0,24,12.75,11.27,11.27,0,0,0,12.75,1.5Z"
    />
    <path
      fill={color}
      d="M16.75,13.47h-8a.72.72,0,0,1,0-1.44h8a.72.72,0,1,1,0,1.44Z"
    />
  </svg>
);

CircleMinus.propTypes = IconType;

export default CircleMinus;
