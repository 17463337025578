import { string } from "prop-types";
import React from "react";

const Footsteps = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.7 708.7" {...props}>
    <path
      fill={color}
      d="M70.7,226.3c21.1-8.8,52.5,17.5,70.7,59c3.6,9.5,7.3,18.9,9.5,29.1c1.4,5.8-2.2,12.4-8,14.6l-47.4,20.4
	c-5.8,2.2-12.4,0.8-16-4.4c-5.8-8.8-10.9-17.5-14.6-27C46.7,275.9,49.6,235.1,70.7,226.3L70.7,226.3z M105,382.3
	c-2.2-5.8,0.8-12.4,5.8-15.3l33.5-19.7c5.1-2.9,12.4-2.2,16,2.2c2.2,2.9,4.4,5.8,6.6,9.5c12.4,21.1,9.5,45.2-6.6,54.7
	c-16,9.5-38.6,0-51-21.1C107.9,388.9,106.4,386,105,382.3L105,382.3z M557.7,451.6c10.2,2.2,19.7,5.1,29.1,8.8
	c5.8,2.2,8.8,8.8,7.3,14.6l-10.2,51c-1.4,5.8-6.6,10.9-12.4,10.9c-10.2,0-20.4-1.4-30.6-2.9c-44.5-8.8-76.5-34.3-72.1-56.8
	C473.9,453.8,513.3,442.1,557.7,451.6L557.7,451.6z M640.1,480c22.6,8,36.5,28.5,30.6,45.9c-5.8,17.5-29.1,25.5-51.8,17.5
	c-3.6-1.4-7.3-2.9-10.2-5.1c-5.1-2.9-8-9.5-5.8-15.3l12.4-36.5c2.2-5.8,8-9.5,13.9-8.8C632.1,477.8,636.4,479.3,640.1,480L640.1,480
	z M250.8,518.6c13.1-18.9,54-13.1,91.1,13.1c8,5.8,16,12.4,23.3,20.4c4.4,4.4,4.4,11.7,0.8,16.8l-29.9,42.3
	c-3.6,5.1-10.2,7.3-16,4.4c-9.5-4.4-18.2-9.5-27-15.3C256.6,574.1,237.7,537.6,250.8,518.6L250.8,518.6z M361.6,633.2
	c-5.1-3.6-5.8-10.9-2.9-16l18.2-34.3c2.9-5.8,9.5-8,15.3-6.6c3.6,1.4,7.3,2.9,10.9,4.4c21.1,10.9,31.3,33.5,22.6,49.6
	c-8.8,16-32.8,20.4-54,8.8C368.2,638.2,364.5,636,361.6,633.2L361.6,633.2z M352.1,310.2c7.3,7.3,14.6,14.6,19.7,23.3
	c3.6,5.1,2.9,11.7-1.4,16.8l-35.7,37.2c-4.4,4.4-10.9,5.1-16,2.2c-8.8-5.8-16.8-12.4-24.1-18.9c-32.8-31.4-45.9-70-30.6-86.8
	C279.9,266.4,319.4,278.8,352.1,310.2L352.1,310.2z M405.4,378.7c15.3,18.9,16,43.7,1.4,55.4c-14.6,11.7-37.9,5.8-53.2-13.1
	c-2.2-2.9-4.4-6.6-6.6-9.5c-2.9-5.1-1.4-12.4,3.6-16l29.9-24.8c5.1-3.6,11.7-3.6,16.8,0C400.3,372.9,403.2,375.8,405.4,378.7
	L405.4,378.7z M653.9,614.2c10.2,0,21.1,0.8,31.3,2.9c5.8,1.4,10.2,6.6,10.2,12.4l-0.8,51c0,5.8-4.4,11.7-10.9,12.4
	c-10.2,2.2-20.4,2.2-31.3,2.9c-45.9,0-83.1-18.9-82.4-41.6C570.8,631.7,608,614.2,653.9,614.2L653.9,614.2z M78,35.3
	C78,60.1,63.4,79.8,46,79.8c-18.2,0-32.8-19.7-32.8-44.5c0-3.6,0.8-8,0.8-11.7c0.7-5.8,6.5-10.9,12.4-10.9h37.9
	c5.8,0,11.7,4.4,12.4,10.9C78,27.3,78,31,78,35.3L78,35.3z M175,13.5c21.9-4.4,47.4,27.7,56.8,72.9c2.2,10.2,3.6,20.4,3.6,31.3
	c0,6.6-4.4,11.7-10.2,13.1L175.7,141c-5.8,1.4-11.7-2.2-14.6-7.3c-4.4-9.5-6.6-19.7-8.8-29.9C142.2,58,153.1,17.9,175,13.5L175,13.5
	z M178.6,175.3c-0.8-5.8,2.9-12.4,8.8-13.9l35.7-12.4c5.8-2.2,12.4,0.8,15.3,5.8c2.2,3.6,3.6,7.3,5.1,10.9
	c8,23.3,0.8,46.6-16.8,52.5c-16.8,5.8-37.2-8-45.2-31.3C180.1,182.6,178.6,179,178.6,175.3L178.6,175.3z"
    />
  </svg>
);

Footsteps.propTypes = {
  color: string,
};

export default Footsteps;
