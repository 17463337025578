import React from "react";

import { IconType } from "../index.types";

const Cross = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.99 24" {...props}>
    <path
      fill={color}
      d="M23.7,22.3a1,1,0,0,1,0,1.41,1,1,0,0,1-1.41,0L12,13.42,1.7,23.71a1,1,0,0,1-1.41,0,1,1,0,0,1,0-1.41L10.59,12,.29,1.71A1,1,0,0,1,.29.3,1,1,0,0,1,1.7.3L12,10.6,22.29.3A1,1,0,0,1,23.7.3a1,1,0,0,1,0,1.41L13.41,12Z"
    />
  </svg>
);

Cross.propTypes = IconType;

export default Cross;
