import { socialType } from "@components/molecules/SocialBlock/index.types";
import { arrayOf, node, shape, string } from "prop-types";

export const logoType = shape({
  scr: string,
  altText: string,
});

export const contactDataType = shape({
  heading: string.isRequired,
  newsLetter: shape({
    path: string,
    label: string,
  }),
  logos: arrayOf(node),
  socials: arrayOf(socialType),
});
