import React from "react";

import { IconType } from "../index.types";

const TimeIcon = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 66 82"
    {...props}
  >
    <path
      d="M9.02337 79.1597C12.3302 74.8023 16.5135 72.1046 20.4544 69.5685C24.0162 67.2811 28.1249 64.6331 30.6921 60.9408C32.3703 58.5166 33.2841 58.5166 34.9686 60.9408C37.5296 64.6331 41.6383 67.2811 45.2 69.5685C49.1471 72.1046 53.3242 74.8023 56.6311 79.1597H9.02337ZM21.4863 25.8831C17.6884 23.4402 13.648 20.8357 10.5463 16.4784C9.502 15.0052 10.3163 14.452 12.1748 15.2663C16.2773 17.0627 23.3075 19.7977 28.2803 19.8723C33.7814 19.9469 46.8286 16.5281 53.1937 14.7566C55.1952 14.2034 56.1649 15.0052 55.1144 16.4784C52.0126 20.8357 47.9723 23.4402 44.1744 25.8831C40.911 27.984 37.1442 30.4083 34.7883 33.7959C33.2468 36.0212 32.4139 36.0212 30.8723 33.7959C28.5227 30.4083 24.7558 27.984 21.4863 25.8831ZM48.0407 52.6674C43.2047 49.4725 38.636 46.4515 38.636 40.9629C38.636 35.4742 43.2047 32.4595 48.0407 29.2583C54.9403 24.6958 63.2697 19.1885 65.3023 3.86008C65.582 1.7964 63.5432 0 60.9511 0H4.71574C2.11749 0 0.0848821 1.7964 0.358382 3.86008C2.39099 19.1885 10.7203 24.702 17.62 29.2583C22.456 32.4533 27.0184 35.4742 27.0184 40.9629C27.0184 46.4515 22.4497 49.4663 17.62 52.6674C10.7265 57.2299 2.39099 62.7372 0.358382 78.0657C0.0848821 80.1294 2.11749 81.9258 4.71574 81.9258H60.9511C63.5432 81.9258 65.582 80.1294 65.3085 78.0657C63.2759 62.7372 54.9466 57.2237 48.0469 52.6674"
      fill={color}
    />
  </svg>
);

TimeIcon.propTypes = IconType;

export default TimeIcon;
