import { string } from "prop-types";
import React from "react";

function generateSVG(options, color, props) {
  const arrowWidth = options.tipWidth;
  const arrowHeight = options.tipHeight;
  const tailLength = options.lineLength - 2;
  const strokeWidth = options.strokeWidth;
  const svgWidth = options.lineLength;
  const svgHeight = arrowHeight;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      {...props}
    >
      <line
        x1={`${tailLength}`}
        y1={`${svgHeight / 2}`}
        x2="0"
        y2={`${svgHeight / 2}`}
        strokeWidth={`${strokeWidth}`}
        strokeLinecap="round"
        stroke={color}
        className="stroke"
      />
      <polyline
        points={`
          ${tailLength - arrowWidth},${arrowHeight / 2 - arrowHeight / 2} 
          ${tailLength},${svgHeight / 2} 
          ${tailLength - arrowWidth},${arrowHeight / 2 + arrowHeight / 2}
        `}
        fill="none"
        stroke={color}
        strokeWidth={`${strokeWidth}`}
        strokeLinecap="round"
        className="stroke"
      />
    </svg>
  );
}

const Arrow = ({ color = "currentColor", ...props }) =>
  generateSVG(
    {
      tipWidth: 2.5,
      tipHeight: 6,
      lineLength: 18,
      strokeWidth: 1,
    },
    color,
    props
  );

Arrow.propTypes = {
  color: string,
};

export default Arrow;
