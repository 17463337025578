import React from "react";

import { IconType } from "../index.types";

const LinkedIn = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      fill={color}
      d="M14,1V13a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H13A1,1,0,0,1,14,1ZM4.4,3.2A1.21,1.21,0,1,0,3.19,4.41,1.21,1.21,0,0,0,4.4,3.2ZM4.23,12V5.32H2.16V12ZM12,8.34c0-1.8-.39-3.19-2.49-3.19a2.21,2.21,0,0,0-2,1.08h0V5.32h-2V12H7.61V8.69C7.61,7.82,7.77,7,8.86,7S9.93,8,9.93,8.75V12H12Z"
    />
  </svg>
);

LinkedIn.propTypes = IconType;

export default LinkedIn;
