import styled from "@emotion/styled";
import { node } from "prop-types";
import React from "react";

const StyledText = styled.span`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const ScreenreaderText = ({ children }) => <StyledText>{children}</StyledText>;

ScreenreaderText.propTypes = {
  children: node.isRequired,
};

export default ScreenreaderText;
