import { NAV_ICONS, NAV_LABELS, PATHS } from "@constants";

export const renderNavs = (keys, locale) =>
  keys.map((key, index) => {
    if (!NAV_LABELS[locale][key] || !PATHS[locale][key]) {
      if (process.env.NODE_ENV !== "test") {
        console.error(`Key not found in paths: ${locale}/${key}`);
      }
      return { label: `MISSING_KEY_${index}`, path: `/missing-path-${index}` };
    }
    return NAV_ICONS[locale][key]
      ? {
          label: NAV_LABELS[locale][key],
          path: PATHS[locale][key],
          icon: NAV_ICONS[locale][key],
        }
      : {
          label: NAV_LABELS[locale][key],
          path: PATHS[locale][key],
        };
  });
