import I18nContext from "@utils/context/I18nContext";
import LocaleContext from "@utils/context/LocaleContext";
import { useContext } from "react";

const useI18n = () => {
  const locale = useContext(LocaleContext);
  const i18n = useContext(I18nContext);
  i18n.setLocale(locale);

  return i18n;
};

export default useI18n;
