import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { string } from "prop-types";
import React from "react";

import DownloadLink from "./components/DownloadLink";
import ExternalLink from "./components/ExternalLink";
import InternalLink from "./components/InternalLink";
import { shouldTreatAsInternal } from "./utils";

const LinkStyling = ({ theme }) => css`
  color: ${theme.brand("primary")};
  cursor: pointer;
  transition: color 0.3s ease-out;
  :hover {
    color: ${theme.brand("black")};
  }
`;

const StyledLink = styled(ExternalLink)`
  ${LinkStyling};
`;

const StyledDownloadLink = styled(DownloadLink)`
  ${LinkStyling};
`;

const StyledInternalLink = styled(InternalLink)`
  ${LinkStyling}
`;

const Link = (props) => {
  const { to, download } = props;
  const hostname =
    typeof window !== "undefined" ? window.location.hostname : "";
  const internal = shouldTreatAsInternal(hostname, to);

  return download || internal ? (
    <>
      {download && <StyledDownloadLink {...props} />}
      {internal && <StyledInternalLink {...props} />}
    </>
  ) : (
    <StyledLink {...props} />
  );
};

Link.propTypes = {
  to: string,
  download: string,
};

export default Link;
