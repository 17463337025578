import { string } from "prop-types";
import React from "react";

const Buy = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 75 81"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M39.144 0h35.074v57.02H54.15l-8.135 9.49a6.007 6.007 0 01-1.631 1.336L23.13 79.744a3.386 3.386 0 01-1.657.433H3.39A3.39 3.39 0 010 76.787v-9.454a3.39 3.39 0 011.608-2.883l13.752-8.497 5.971-14.63a10.49 10.49 0 016.378-5.981l11.435-3.836V0zm33.574 55.52H55.436l6.231-7.269.008-.009a6.184 6.184 0 00-.984-8.844c-2.363-1.822-6.06-1.684-8.28.31l-11.767 10.57V1.5h32.074v54.02zM39.144 33.088v18.538l-1.204 1.082a.75.75 0 001.002 1.116l14.472-13c1.68-1.51 4.571-1.618 6.36-.239a4.684 4.684 0 01.75 6.695L44.876 65.534c-.346.404-.76.743-1.224 1.003l-21.254 11.9c-.282.157-.6.24-.924.24H3.39a1.89 1.89 0 01-1.889-1.89v-9.454c0-.654.339-1.262.897-1.607L16.356 57.1l.208-.128.092-.227L22.72 41.89a8.991 8.991 0 015.466-5.126l10.958-3.676zm21.72-1.074c-.842.712-1.909 1.068-3.203 1.068-1.444 0-2.608-.432-3.492-1.294-.884-.884-1.326-2.038-1.326-3.46v-2.75c0-.086.043-.129.129-.129h6.695c.237 0 .388-.097.452-.291l.13-.356c.107-.302 0-.453-.324-.453h-6.953c-.086 0-.13-.043-.13-.129v-2.361c0-.086.044-.13.13-.13h7.794c.237 0 .388-.096.453-.29l.13-.356c.107-.302 0-.453-.324-.453h-8.053c-.086 0-.13-.043-.13-.13v-2.845c0-1.424.443-2.566 1.327-3.429.884-.862 2.048-1.293 3.492-1.293 1.294 0 2.361.366 3.202 1.1.863.71 1.37 1.67 1.52 2.878.043.237.162.355.356.355h.065l.938-.129c.237-.043.355-.172.355-.388v-.065c-.194-1.617-.873-2.9-2.037-3.848-1.143-.95-2.609-1.423-4.399-1.423-1.983 0-3.568.582-4.754 1.746-1.186 1.143-1.779 2.663-1.779 4.56v2.782c0 .086-.043.129-.129.129h-2.2a.402.402 0 00-.29.13.35.35 0 00-.097.258v.323c0 .108.032.205.097.292a.473.473 0 00.29.097H51c.086 0 .13.043.13.129v2.361c0 .086-.044.13-.13.13h-2.2a.403.403 0 00-.29.129.35.35 0 00-.097.258v.324c0 .108.032.205.097.29a.473.473 0 00.29.098H51c.086 0 .13.043.13.13v2.651c0 1.92.592 3.46 1.778 4.625 1.208 1.165 2.792 1.747 4.754 1.747 1.747 0 3.191-.464 4.334-1.391 1.165-.949 1.865-2.21 2.102-3.784.022-.13-.01-.226-.097-.291a.414.414 0 00-.258-.162l-.906-.162c-.259-.043-.399.065-.42.324-.173 1.207-.69 2.167-1.553 2.878z"
      clipRule="evenodd"
    ></path>
  </svg>
);

Buy.propTypes = {
  color: string,
};

export default Buy;
