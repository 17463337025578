/**
 * Removes any slashes at the end of a string.
 *
 * @param {string} str
 * @returns {string}
 */
function removeTrailingSlashes(str) {
  return str.replace(/\/+$/, "");
}

export default removeTrailingSlashes;
