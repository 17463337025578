import Arrow from "./components/Arrow";
import ArrowBack from "./components/ArrowBack";
import At from "./components/At";
import BottleAndCan from "./components/BottleAndCan";
import Buy from "./components/Buy";
import Chevron from "./components/Chevron";
import CircleMinus from "./components/CircleMinus";
import CirclePlus from "./components/CirclePlus";
import Coins from "./components/Coins";
import Cross from "./components/Cross";
import DoubleHands from "./components/DoubleHands";
import Download from "./components/Download";
import Facebook from "./components/Facebook";
import Footsteps from "./components/Footsteps";
import Give from "./components/Give";
import Hand from "./components/Hand";
import HandWithHeart from "./components/HandWithHeart";
import Heart from "./components/Heart";
import Instagram from "./components/Instagram";
import LinkedIn from "./components/LinkedIn";
import Mail from "./components/Mail";
import Menu from "./components/Menu";
import MoneyIcon from "./components/MoneyIcon";
import PaintPalette from "./components/PaintPalette";
import PdfFile from "./components/PdfFile";
import Pen from "./components/Pen";
import Phone from "./components/Phone";
import PhotoFile from "./components/PhotoFile";
import Pinterest from "./components/Pinterest";
import Presentation from "./components/Presentation";
import Search from "./components/Search";
import Share from "./components/Share";
import StuffIcon from "./components/StuffIcon";
import Sustainable from "./components/Sustainable";
import TimeIcon from "./components/TimeIcon";
import VideoFile from "./components/VideoFile";
import Volunteer from "./components/Volunteer";
import World from "./components/World";
import X from "./components/X"; // also exported as "twitter"
import Youtube from "./components/Youtube";

export default {
  arrow: Arrow,
  arrowback: ArrowBack,
  at: At,
  bottleAndCan: BottleAndCan,
  buy: Buy,
  chevron: Chevron,
  circleMinus: CircleMinus,
  circlePlus: CirclePlus,
  coins: Coins,
  cross: Cross,
  doubleHands: DoubleHands,
  download: Download,
  facebook: Facebook,
  footsteps: Footsteps,
  give: Give,
  hand: Hand,
  handWithHeart: HandWithHeart,
  heart: Heart,
  instagram: Instagram,
  linkedIn: LinkedIn,
  mail: Mail,
  menu: Menu,
  money: MoneyIcon,
  paintPalette: PaintPalette,
  pdfFile: PdfFile,
  pen: Pen,
  phone: Phone,
  photoFile: PhotoFile,
  pinterest: Pinterest,
  presentation: Presentation,
  search: Search,
  share: Share,
  stuff: StuffIcon,
  sustainable: Sustainable,
  time: TimeIcon,
  twitter: X,
  videoFile: VideoFile,
  volunteer: Volunteer,
  world: World,
  x: X,
  youtube: Youtube,
};
