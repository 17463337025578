/**
 *
 * @param {string} url
 * @param {object} options
 * @param {boolean} options.removeProtocol
 * @param {boolean} options.removeWWW
 * @returns {string | null}
 */
function cleanUrl(url, { removeProtocol = true, removeWWW = true } = {}) {
  if (typeof url !== "string") {
    return null;
  }
  let cleanedUrl = url;

  if (removeWWW) {
    cleanedUrl = cleanedUrl.replace("www.", "");
  }

  if (removeProtocol) {
    cleanedUrl = cleanedUrl.replace(/^(http|https):\/\//, "");
  }

  return cleanedUrl;
}

export default cleanUrl;
