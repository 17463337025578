import { LOCALE } from "@constants";
import { ICONS } from "@constants/icons";
import { arrayOf, number, oneOf, shape, string } from "prop-types";

export const navItemType = shape({
  label: string.isRequired,
  path: string.isRequired,
});

export const ctaType = shape({
  navItemType,
  icon: oneOf([ICONS.HAND, ICONS.HEART]),
});

export const menuItemsType = shape({
  main: arrayOf(navItemType),
  sub: arrayOf(navItemType),
  cta: arrayOf(ctaType),
});

export const headerItemsType = {
  locale: oneOf(Object.values(LOCALE)),
  localeOptions: arrayOf(
    shape({
      to: string.isRequired,
      locale: oneOf(Object.values(LOCALE)).isRequired,
    }),
  ).isRequired,
  headerItems: shape({
    main: arrayOf(navItemType),
    cta: arrayOf(ctaType),
  }),
  menuItems: arrayOf(
    shape({
      navItemType,
      items: arrayOf(navItemType),
    }),
  ),
  currentPath: string,
};

export const ImageType = {
  data: shape({
    srcSet: string.isRequired,
    sizes: string.isRequired,
    src: string.isRequired,
    width: number.isRequired,
    height: number.isRequired,
    aspectRatio: number.isRequired,
    base64: string.isRequired,
  }).isRequired,

  title: string,
  alt: string.isRequired,
};

export const AccordionType = {
  title: string.isRequired,
  body: string.isRequired,
  slices: arrayOf(
    shape({
      title: string.isRequired,
      content: string.isRequired,
      callToAction: shape({
        label: string.isRequired,
        path: string.isRequired,
      }),
    }).isRequired,
  ),
};

export const FunctionalCopyType = {
  value: string.isRequired,
  key: string.isRequired,
};
