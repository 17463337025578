import React from "react";

import { IconType } from "../index.types";

const Phone = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
    <path
      fill={color}
      d="M1.9,12.69l-.58-2.48L4,9.06l1.52,1.86a9.92,9.92,0,0,0,5.39-5.39L9.06,4l1.15-2.69,2.48.57A10.85,10.85,0,0,1,1.9,12.69ZM1.84,14A12.15,12.15,0,0,0,14,1.85,1.24,1.24,0,0,0,13,.63L10.45,0A1.26,1.26,0,0,0,9,.76L7.84,3.54A1.25,1.25,0,0,0,8.19,5l1.1.89a8.53,8.53,0,0,1-3.4,3.39L5,8.19a1.25,1.25,0,0,0-1.46-.36L.76,9A1.26,1.26,0,0,0,0,10.45L.62,13A1.26,1.26,0,0,0,1.84,14Z"
    />
  </svg>
);

Phone.propTypes = IconType;

export default Phone;
