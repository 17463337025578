import cleanUrl from "@utils/cleanUrl";

export const stripLink = (hostname = "", href = "") => {
  if (hostname === "") {
    return href;
  }

  const _hostname = cleanUrl(hostname);
  const withoutProtocol = cleanUrl(href);
  const regex = new RegExp(`^(?:www\\.)?${_hostname}`);

  return regex.test(withoutProtocol)
    ? withoutProtocol.replace(regex, "")
    : href;
};

export const shouldTreatAsInternal = (hostname = "", href = "") => {
  // When href starts with # or a single / we can assume it's internal
  if (href.startsWith("#") || /^\/(?!\/)/.test(href)) {
    return true;
  }

  // Build dynamic regex based on hostname
  const regex = new RegExp(`^(?:www\\.)?${cleanUrl(hostname)}/.+`);

  // Check if href matches the regex pattern
  return regex.test(cleanUrl(href));
};
