export const mocksNL = [
  {
    value: "Bezig met laden",
    key: "common.loading",
  },
  {
    value: "Meer over het land",
    key: "buttons.more_about_country",
  },
  {
    value: "Meer over de regio",
    key: "buttons.more_about_region",
  },
  {
    value: "Vacatures",
    key: "headings.vacancies",
  },
  {
    value: "Neem contact met ons op",
    key: "headings.contact_us",
  },
  {
    value: "Volg ons",
    key: "heading.follow_us",
  },
  {
    value: "routebeschrijving",
    key: "button.directions",
  },
  {
    value: "Lees meer",
    key: "button.read_more",
  },
  {
    value: "Zoek een winkel",
    key: "stores.search_label",
  },
  {
    value: "Bekijk alle berichten",
    key: "button.all_articles",
  },
  {
    value: "Zoek",
    key: "button.search",
  },
  {
    value: "Postcode of plaats",
    key: "stores.search_placeholder",
  },
  {
    value: "Meer over onze winkels",
    key: "button.about_stores",
  },
  {
    value: "Alle projecten",
    key: "button.all_projects",
  },
  {
    value: "Meer over wat we doen",
    key: "button.about_what_we_do",
  },
  {
    value: "Meer over onze missie",
    key: "button.about_mission",
  },
  {
    value: "Doneer",
    key: "button.donate",
  },
  {
    value: "Onze winkels",
    key: "button.search_label",
  },
  {
    value: "Lees meer over de regio",
    key: "buttons.read_more_region",
  },
  {
    value: "Lees meer over het land",
    key: "buttons.read_more_country",
  },
  {
    value: "Over deze winkel",
    key: "map.buttons.view-store",
  },
  {
    value: "Meer over samenwerken",
    key: "button.collaboration",
  },
  {
    value: "Meer over adverteren",
    key: "button.advertisement",
  },
  {
    value: "Meld je aan voor onze nieuwsbrief",
    key: "headings.subscribe_newsletter",
  },
  {
    value:
      "En blijf op de hoogte van onze werkzaamheden binnen uitbuitingssituaties",
    key: "body.subscribe_newsletter",
  },
  {
    value: "Openingstijden",
    key: "heading.business_hours",
  },
  {
    value: "Volgende",
    key: "pagination.next",
  },
  {
    value: "Vorige",
    key: "pagination.previous",
  },
  {
    value: "Meer over ons werk in",
    key: "button.where_we_work_cta",
  },
];

export const mocksEN = [
  {
    value: "Loading",
    key: "common.loading",
  },
  {
    value: "More about the country",
    key: "buttons.more_about_country",
  },
  {
    value: "More about the region",
    key: "buttons.more_about_region",
  },
  {
    value: "Job vacancies",
    key: "headings.vacancies",
  },
  {
    value: "Contact us",
    key: "headings.contact_us",
  },
  {
    value: "Follow us",
    key: "heading.follow_us",
  },
  {
    value: "directions",
    key: "button.directions",
  },
  {
    value: "Read more",
    key: "button.read_more",
  },
  {
    value: "Search for a store",
    key: "stores.search_label",
  },
  {
    value: "View al messages",
    key: "button.all_articles",
  },
  {
    value: "Search",
    key: "button.search",
  },
  {
    value: "Zipcode or city",
    key: "stores.search_placeholder",
  },
  {
    value: "More about our stores",
    key: "button.about_stores",
  },
  {
    value: "All projects",
    key: "button.all_projects",
  },
  {
    value: "More about what we do",
    key: "button.about_what_we_do",
  },
  {
    value: "More about our mission",
    key: "button.about_mission",
  },
  {
    value: "Donate",
    key: "button.donate",
  },
  {
    value: "Our stores",
    key: "button.search_label",
  },
  {
    value: "Read more about the region",
    key: "buttons.read_more_region",
  },
  {
    value: "Read more about the country",
    key: "buttons.read_more_country",
  },
  {
    value: "About this store",
    key: "map.buttons.view-store",
  },
  {
    value: "More about collaboration",
    key: "button.collaboration",
  },
  {
    value: "More about advertisement",
    key: "button.advertisement",
  },
  {
    value: "Subscribe to our newsletter",
    key: "headings.subscribe_newsletter",
  },
  {
    value: "And stay up to date with our work within exploitation situations",
    key: "body.subscribe_newsletter",
  },
  {
    value: "Business hours",
    key: "heading.business_hours",
  },
  {
    value: "Next",
    key: "pagination.next",
  },
  {
    value: "Previous",
    key: "pagination.previous",
  },
  {
    value: "More about our work in",
    key: "button.where_we_work_cta",
  },
];
