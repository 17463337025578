import React from "react";

import { IconType } from "../index.types";

const Hand = ({ color = "currentColor", innerColor = "none", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.6 26.22" {...props}>
    <path
      fill={innerColor}
      className="inner-color"
      d="M16.84,9.05v6.83S17.11,19,14.29,19v6.49H3.89V18.31L3,17.77A4.4,4.4,0,0,1,.71,13.94V8.25A1.82,1.82,0,0,1,2.06,6.49V2.57a1.85,1.85,0,1,1,3.7,0v.84a1.85,1.85,0,1,1,3.7,0v.85a1.85,1.85,0,1,1,3.69,0v1.5a1.85,1.85,0,1,1,3.7,0V9a.13.13,0,0,1,0,.06A0,0,0,0,1,16.84,9.05Z"
    />
    <path
      fill={color}
      className="outer-color"
      d="M17.6,9V5.78a2.61,2.61,0,0,0-3.82-2.3,2.59,2.59,0,0,0-2.46-1.8,2.62,2.62,0,0,0-1.46.44,2.49,2.49,0,0,0-.4-.52,2.59,2.59,0,0,0-3.28-.32A2.93,2.93,0,0,0,5.77.76,2.57,2.57,0,0,0,3.94,0a2.6,2.6,0,0,0-2.6,2.59V6A2.56,2.56,0,0,0,0,8.27v5.67a5.14,5.14,0,0,0,2.61,4.48l.56.32v6.73a.75.75,0,0,0,1.5,0V17.86l-1.32-.75A3.64,3.64,0,0,1,1.5,13.94V8.27a1.11,1.11,0,0,1,.8-1.06,1.45,1.45,0,0,1,.29,0h5a1.12,1.12,0,0,1,.62.18,1.22,1.22,0,0,1,.43.47A.86.86,0,0,1,8.68,8a1.18,1.18,0,0,1,0,.2v0A1.1,1.1,0,0,1,8.4,9a1.07,1.07,0,0,1-.77.32H3.68v2.93l.79,0a4.18,4.18,0,0,1,2.7,1,3.77,3.77,0,0,1,1,2.86.75.75,0,0,0,1.5,0,5.24,5.24,0,0,0-1.48-3.95,5.18,5.18,0,0,0-2.6-1.28h2a2.57,2.57,0,0,0,1.83-.76l0-.06a2.62,2.62,0,0,0,1.81.73,2.56,2.56,0,0,0,1.47-.46A2.57,2.57,0,0,0,15,11.56a2.63,2.63,0,0,0,1.09-.24v4.62a2.72,2.72,0,0,1-.52,1.83,1.64,1.64,0,0,1-1.28.47h-.75v7.23a.75.75,0,0,0,1.5,0v-5.8a2.83,2.83,0,0,0,1.63-.89,4.14,4.14,0,0,0,.92-2.9V9.22A2.17,2.17,0,0,0,17.6,9ZM5,5.67H2.84V2.59A1.09,1.09,0,0,1,3.94,1.5,1.09,1.09,0,0,1,5,2.59Zm3.69.26a2.44,2.44,0,0,0-.86-.24l-.3,0h-1V3.44a1.1,1.1,0,0,1,1.1-1.1,1.09,1.09,0,0,1,1.09,1.1Zm3.69,2.25a1.1,1.1,0,0,1-2.19.09V8.11h0V4.28a1.1,1.1,0,1,1,2.19,0ZM15,10.06A1.09,1.09,0,0,1,13.91,9V5.78a1.1,1.1,0,1,1,2.19,0V9a.17.17,0,0,0,0,.07v0A1.09,1.09,0,0,1,15,10.06Z"
    />
    <path fill={color} d="M10.21,8.1h0v0Zm0,0h0v0Zm0,0h0v0Z" />
  </svg>
);

Hand.propTypes = IconType;

export default Hand;
