import { string } from "prop-types";
import React from "react";

const Download = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.18 15.22" {...props}>
    <g>
      <path
        fill={color}
        d="M8.38,10.74,4.84,7.21a1,1,0,0,1,0-1.42,1,1,0,0,1,1.42,0L8.09,7.62V1a1,1,0,0,1,2,0V7.62l1.82-1.83a1,1,0,1,1,1.42,1.42L9.79,10.74A1,1,0,0,1,8.38,10.74Z"
      />
      <path
        fill={color}
        d="M17.13,15.22H1a1,1,0,0,1-1-1V9.52a1,1,0,1,1,2.09,0v3.62h14V9.52a1,1,0,1,1,2.09,0v4.66A1,1,0,0,1,17.13,15.22Z"
      />
    </g>
  </svg>
);

Download.propTypes = {
  color: string,
};

export default Download;
