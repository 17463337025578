const { RECORD_TYPE, RECORD_TYPE_LEGACY } = require("./recordTypes");

const LOCALE = {
  NL: "nl",
  EN: "en",
};

const LAYOUT = {
  ROW: "row",
  COLUMN: "column",
};

const PATHS = {
  [LOCALE.NL]: {
    home: "/nl",
    about_mission: "/nl/onze-missie",
    join_fight: "/nl/strijd-mee",
    about_stores: "/nl/onze-winkels",
    programs: "/nl/projecten",
    about_what_we_do: "/nl/wat-we-doen",
    partners: "/nl/partners",
    cookies: "/nl/cookies",
    privacy: "/nl/privacy",
    currently: "/nl/actueel",
    publications: "/nl/publicaties",
    our_organisation: "/nl/onze-organisatie",
    regions: "/nl/regios",
    countries: "/nl/landen",
    contact: "/nl/contact",
    jobs: "/nl/werken-bij",
    newsLetter:
      "https://secured.terredeshommes.nl/nieuwsbrief?_ga=2.221992986.369177288.1588753095-332109269.1574154922",
    donate:
      "https://secured.terredeshommes.nl/algemene_donatie?_ga=2.34384162.204433774.1658147315-2112255460.1658147315",
    become_donor: "https://secured.terredeshommes.nl/algemene_donatie",
    collaboration: "/nl/samenwerken",
    advertisement: "/nl/adverteren",
    search: "/nl/zoeken",
    petition: "/nl/strijd-mee/teken-onze-petities",
    campaigns: "/nl/campagnes",
  },
  [LOCALE.EN]: {
    home: "/en",
    about_mission: "/en/our-mission",
    join_fight: "/en/join-the-fight",
    about_stores: "/en/our-stores",
    programs: "/en/projects",
    about_what_we_do: "/en/what-we-do",
    partners: "/en/partners",
    cookies: "/en/cookies",
    privacy: "/en/privacy",
    currently: "/en/latest",
    publications: "/en/publications",
    our_organisation: "/en/our-organisation",
    regions: "/en/regions",
    countries: "/en/countries",
    contact: "/en/contact",
    jobs: "/en/jobs",
    newsLetter:
      "https://secured.terredeshommes.nl/nieuwsbrief?_ga=2.221992986.369177288.1588753095-332109269.1574154922",
    donate: "https://secured.terredeshommes.nl/donation",
    become_donor:
      "https://secured.terredeshommes.nl/donation?_ga=2.171202464.1610801280.1639405468-891253147.1637222903",
    collaboration: "/en/collaborate",
    advertisement: "/en/advertise",
    search: "/en/search",
    //TODO: get propper url.
    petition: "/en/strijd-mee/teken-onze-petities",
    campaigns: "/en/campaigns",
  },
};

const NAV_LABELS = {
  [LOCALE.NL]: {
    home: "Home",
    about_mission: "Onze missie",
    join_fight: "Strijd mee",
    about_stores: "Onze winkels",
    // eslint-disable-next-line quotes
    programs: "Programma's",
    about_what_we_do: "Wat we doen",
    partners: "Partners",
    cookies: "Cookies",
    privacy: "Privacy",
    currently: "Actueel",
    publications: "Publicaties",
    our_organisation: "Onze organisatie",
    // eslint-disable-next-line quotes
    regions: "Regio's",
    countries: "Waar we werken",
    contact: "Contact",
    jobs: "Werken bij",
    newsLetter: "Meld je aan",
    donate: "Doneer",
    become_donor: "Wordt donateur",
    search: "Zoeken",
    petition: "Teken de petitie",
  },
  [LOCALE.EN]: {
    home: "Home",
    about_mission: "Our mission",
    join_fight: "Fight with us",
    about_stores: "Our stores",
    programs: "Programs",
    about_what_we_do: "What we do",
    partners: "Partners",
    cookies: "Cookies",
    privacy: "Privacy",
    currently: "Latest",
    publications: "Publications",
    our_organisation: "Our organisation",
    regions: "Regions",
    countries: "Where we work",
    contact: "Contact",
    jobs: "Jobs",
    newsLetter: "Sign up",
    donate: "Donate",
    become_donor: "Become a donor",
    search: "Search",
    petition: "EN: Teken de petitie",
  },
};

const NAV_ICONS = {
  [LOCALE.NL]: {
    search: "search",
  },
  [LOCALE.EN]: {
    search: "search",
  },
};

module.exports = {
  LOCALE,
  LAYOUT,
  PATHS,
  NAV_LABELS,
  NAV_ICONS,
  RECORD_TYPE,
  RECORD_TYPE_LEGACY,
};
