/**
 * @param {Array} arr
 * @param {number} chunkSize
 * @returns {Array}
 */
function chunkArray(arr, chunkSize) {
  const result = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

export default chunkArray;
