import React from "react";

import { IconType } from "../index.types";

const Youtube = ({ color = "currentColor", ...props }) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6807 8.85547C31.6807 8.85547 31.3678 6.65031 30.4082 5.67893C29.1918 4.40462 27.8276 4.39858 27.2021 4.32391C22.7243 4 16.0071 4 16.0071 4H15.9929C15.9929 4 9.27609 4 4.79787 4.32391C4.17244 4.39858 2.80889 4.40462 1.59182 5.67893C0.632178 6.65031 0.32 8.85547 0.32 8.85547C0.32 8.85547 0 11.445 0 14.0345V16.4626C0 19.0517 0.32 21.6412 0.32 21.6412C0.32 21.6412 0.632178 23.8464 1.59182 24.8178C2.80889 26.0924 4.40782 26.0516 5.12 26.1852C7.68035 26.4309 16 26.507 16 26.507C16 26.507 22.7243 26.4967 27.2021 26.1732C27.8276 26.0985 29.1918 26.0924 30.4082 24.8178C31.3678 23.8464 31.6807 21.6412 31.6807 21.6412C31.6807 21.6412 32 19.0517 32 16.4626V14.0345C32 11.445 31.6807 8.85547 31.6807 8.85547ZM12.6965 19.4041L12.6951 10.4132L21.3415 14.9241L12.6965 19.4041Z"
      fill={color}
    />
  </svg>
);

Youtube.propTypes = IconType;

export default Youtube;
