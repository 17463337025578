import sitemap from "./sitemap";

const getNavigationItem = (key, locale) => {
  const item = sitemap[key];

  if (!item) {
    throw new Error(`Item for [${key}] is missing in sitemap.`);
  }

  if (!item[locale]) {
    return null;
  }

  const { label, path } = item[locale];

  return {
    label,
    path: path.substr(0, 4) === "http" ? path : `/${locale}/${path}`,
  };
};

export default getNavigationItem;
