import { string } from "prop-types";
import React from "react";

const Facebook = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.09 15" {...props}>
    <path
      fill={color}
      d="M8.72,15V9.73h1.76l.34-2.18H8.72V6.13A1.09,1.09,0,0,1,10,5h1V3.09A12.33,12.33,0,0,0,9.22,3,2.66,2.66,0,0,0,6.37,5.88V7.55H4.45V9.73H6.37V15a7.55,7.55,0,1,1,2.35,0Z"
    />
  </svg>
);

Facebook.propTypes = {
  color: string,
};

export default Facebook;
