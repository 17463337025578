import React from "react";

import { LinkType } from "../index.types";

const ExternalLink = ({ to, className, children, ...other }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={to}
    className={className}
    {...other}
  >
    {children}
  </a>
);

ExternalLink.propTypes = LinkType;

export default ExternalLink;
