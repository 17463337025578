import React from "react";

import { IconType } from "../index.types";

const PhotoFile = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 43 40" fill="none" {...props}>
    <rect
      x="7.9165"
      y="0.75"
      width="34.3333"
      height="26.5694"
      rx="3.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M4.50928 13.7363L1.03531 26.7014C0.463539 28.8352 1.72987 31.0286 3.86374 31.6003L29.3121 38.4192C31.446 38.991 33.6393 37.7247 34.2111 35.5908L35.6189 30.3369"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.76416 25.382L15.3877 17.5407C16.943 15.941 19.5064 15.9229 21.0841 17.5006L21.689 18.1055C22.0795 18.496 22.7127 18.496 23.1032 18.1055L28.8246 12.3841C30.3867 10.822 32.9194 10.822 34.4814 12.3841L42.403 20.3057"
      stroke={color}
      strokeWidth="1.5"
    />
    <circle
      cx="16.7225"
      cy="8.95833"
      r="2.83333"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);

PhotoFile.propTypes = IconType;

export default PhotoFile;
