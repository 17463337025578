export const initiateCheckout = (location) => {
  if (typeof window === "undefined") {
    return;
  }

  if (process.env.NODE_ENV === "development") {
    return console.log(
      "%c [Initiate Checkout util] " +
        `%cSending Initiate Checkout event from ${location}`,
      "color: #4267B2; font-weight: bold",
      "color: black;",
    );
  }

  if (typeof window.fbq !== "undefined") {
    window.fbq("track", "InitiateCheckout", {
      content_category: location,
    });
  }
};
