import Icon from "@components/atoms/Icon";
import Link from "@components/atoms/Link";
import { ICONS } from "@constants/icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import navigate from "@utils/navigate";
import { gsap } from "gsap";
import { any, bool, func, oneOf, oneOfType, string } from "prop-types";
import React, { useCallback, useRef } from "react";

const baseStyling = ({ theme, liquid }) => css`
  font-family: ${theme.get("type.fontFamily.secondary")};
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border: none;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2rem;
  ${liquid &&
  css`
    filter: url("#ripplefilter");
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        filter: none;
      }
    }
  `};
`;

const StyledBaseButton = styled.button`
  ${baseStyling};
`;

const StyledBaseAnchor = styled(Link)`
  text-decoration: none;
  ${baseStyling};
`;

const SvgFilter = styled.svg`
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
`;

const ButtonContent = styled.div`
  position: relative;
  pointer-events: none;
`;

export const StyledIcon = styled(Icon)`
  width: 1rem;
  position: relative;
  pointer-events: none;
`;

const BaseButton = ({
  onClick,
  to,
  children,
  className,
  liquid,
  iconType = ICONS.ARROW,
}) => {
  const ripplefilterImage = useRef();
  const ripplefilterDispmap = useRef();

  const animatedClick = useCallback(
    (e) => {
      if (!ripplefilterImage.current) {
        return;
      }

      const tl = gsap.timeline();
      tl.fromTo(
        ripplefilterImage.current,
        {
          attr: {
            x: e.nativeEvent.offsetX,
            y: e.nativeEvent.offsetY,
            width: 0,
            height: 0,
          },
        },
        {
          duration: 3,
          attr: {
            x: "-=150",
            y: "-=150",
            width: 300,
            height: 300,
          },
        },
      );
      tl.fromTo(
        ripplefilterDispmap.current,
        { attr: { scale: 20 } },
        { duration: 3, attr: { scale: 0 } },
        0,
      );
      tl.call(
        (e) => {
          to ? navigate(to) : onClick(e);
        },
        null,
        1,
      );
    },
    [onClick, to, ripplefilterImage, ripplefilterDispmap],
  );

  return (
    <>
      {to ? (
        liquid ? (
          <StyledBaseButton
            onClick={liquid ? animatedClick : navigate(to)}
            className={className}
            liquid={liquid}
          >
            <ButtonContent>{children}</ButtonContent>
            <StyledIcon className="icon" type={iconType} />
          </StyledBaseButton>
        ) : (
          <StyledBaseAnchor to={to} className={className} liquid={liquid}>
            <ButtonContent>{children}</ButtonContent>
            <StyledIcon className="icon" type={iconType} />
          </StyledBaseAnchor>
        )
      ) : (
        <StyledBaseButton
          onClick={liquid ? animatedClick : onClick}
          className={className}
          liquid={liquid}
        >
          <ButtonContent>{children}</ButtonContent>
          {iconType !== false && (
            <StyledIcon className="icon" type={iconType} />
          )}
        </StyledBaseButton>
      )}
      {liquid && (
        <SvgFilter xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="ripplefilter">
              <feImage
                ref={ripplefilterImage}
                href={"/images/ripple-effect.png"}
                x="30"
                y="20"
                width="0"
                height="0"
                result="ripple"
              />
              <feDisplacementMap
                ref={ripplefilterDispmap}
                xChannelSelector="R"
                yChannelSelector="G"
                colorInterpolationFilters="sRGB"
                in="SourceGraphic"
                in2="ripple"
                result="dm"
              />
              <feComposite operator="in" in2="ripple" />
              <feComposite in2="SourceGraphic" />
            </filter>
          </defs>
        </SvgFilter>
      )}
    </>
  );
};

BaseButton.propTypes = {
  onClick: func,
  to: string,
  children: any,
  className: string,
  liquid: bool,
  iconType: oneOfType([bool, oneOf(Object.values(ICONS))]),
};

export default BaseButton;
