import { shouldTreatAsInternal } from "@components/atoms/Link/utils";
import { navigate as gatsbyNavigate } from "gatsby";

const browserNavigate = (href, replace) => {
  if (replace) {
    return window.location.replace(href);
  }

  window.location.href = href;
};

export default (to, options = { replace: false }) => {
  if (typeof window === "undefined") {
    return;
  }
  const { hostname } = window.location;

  if (shouldTreatAsInternal(hostname, to)) {
    return gatsbyNavigate(to, options);
  }

  return browserNavigate(to, options.replace);
};
