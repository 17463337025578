import { string } from "prop-types";
import React from "react";

import { IconType } from "../index.types";

const Heart = ({ color = "currentColor", innerColor = "none", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.49 26.48" {...props}>
    <path
      fill={innerColor}
      className="inner-color"
      d="M15.54,4.84,17.4,3A7.51,7.51,0,0,1,28,2.88a7.59,7.59,0,0,1,.18,10.7l-.09.09L16.39,25.39h0a1.2,1.2,0,0,1-1.71,0L3,13.67l-.09-.09a7.58,7.58,0,0,1,.19-10.7A7.5,7.5,0,0,1,13.67,3l1.87,1.87L17.41,3l.09-.1"
    />
    <path
      fill={color}
      className="outer-color"
      d="M18,3.5,16.07,5.38a.79.79,0,0,1-1.07,0L13.13,3.5a6.84,6.84,0,0,0-9.54-.08,6.81,6.81,0,0,0-.17,9.63l.07.06L15.21,24.84a.46.46,0,0,0,.65,0l7.65-7.64a.75.75,0,0,1,1.06,1.06L16.93,25.9a1.94,1.94,0,0,1-1.39.58,2,2,0,0,1-1.4-.58L2.34,14.1a8.31,8.31,0,0,1,.2-11.76,8.35,8.35,0,0,1,11.66.1l1.34,1.34L17,2.34A8.31,8.31,0,0,1,31.06,7.92a.75.75,0,0,1-1.5.07,6.83,6.83,0,0,0-6.68-6.48A6.63,6.63,0,0,0,18,3.42"
    />
    <path
      fill={color}
      className="outer-color"
      d="M33.49,14a.72.72,0,0,1-.06.29.66.66,0,0,1-.17.24l-3.91,3.91a.75.75,0,0,1-1.06,0,.75.75,0,0,1,0-1.06l2.64-2.63H21.64a.75.75,0,0,1,0-1.5h9.29L28.29,10.6a.75.75,0,0,1,0-1.06.74.74,0,0,1,1.06,0l3.91,3.91a.66.66,0,0,1,.17.24A.72.72,0,0,1,33.49,14Z"
    />
  </svg>
);

Heart.propTypes = {
  ...IconType,
  innerColor: string,
};

export default Heart;
