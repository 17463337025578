import { string } from "prop-types";
import React from "react";

const Give = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 75 76"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M56.92 56.743l6.384-6.637a5.591 5.591 0 118.703 7.022L58.98 72.058a6.524 6.524 0 01-5.028 2.367h-20.75a6.527 6.527 0 01-3.155-.813l-9.607-5.302a12.017 12.017 0 00-5.807-1.496H3.144A2.145 2.145 0 011 64.669V50.266c0-1.184.96-2.144 2.144-2.144H7.67c.761 0 1.5-.227 2.15-.623 2.148-1.306 4.832-2.102 7.756-2.155.103-.004.21-.004.314-.004 1.66 0 3.247.24 4.708.68 0 0 4.758 1.131 9.651 5.2 0 0 4.18 4.135 7.056 4.442l14.304.001a5.015 5.015 0 015.014 5.016 5.013 5.013 0 01-5.012 5.016l-20.976.001-10.251-7.258"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M32.633 1.171a.75.75 0 00-1.24-.842l.62.421a81.73 81.73 0 00-.621-.42L31.39.33l-.003.003-.006.01-.018.028a5.453 5.453 0 00-.246.436 6.355 6.355 0 00-.455 1.186c-.195.716-.303 1.636-.056 2.616H9.627l.438 1.04 2.401 5.718-1.549 2.684a6.794 6.794 0 00-.91 3.398v20.162h64.382v-5.73h.002v-5.217c0-5.418-3.719-9.964-8.736-11.232-3.358-.892-5.423-1.244-18.294-2.763-6.321-.746-10.079-2.713-12.24-4.44-1.083-.865-1.774-1.677-2.19-2.26a6.71 6.71 0 01-.432-.684 3.56 3.56 0 01-.117-.239l-.18-.437h-.024c-.306-.797-.243-1.58-.068-2.223a4.86 4.86 0 01.482-1.149l.035-.057.007-.01zm0 0l-.62-.42.62.42zM11.885 6.11l2.108 5.02.145.344-.187.322-1.735 3.006a5.294 5.294 0 00-.71 2.648v12.932h61.385v-3.717c0-4.716-3.238-8.677-7.607-9.779l-.01-.002c-3.239-.86-5.19-1.202-18.09-2.725-6.566-.775-10.595-2.836-13-4.756-1.198-.958-1.984-1.874-2.475-2.563a8.505 8.505 0 01-.468-.73H11.885zm-.378 25.774v4.227h61.382v-4.227H11.507z"
      clipRule="evenodd"
    ></path>
  </svg>
);

Give.propTypes = {
  color: string,
};

export default Give;
