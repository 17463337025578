import React from "react";

import { IconType } from "../index.types";

const Chevron = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15" {...props}>
    <path
      fill={color}
      d="M8 7.5c0 .3-.1.5-.3.7l-6 6.5c-.4.4-1 .4-1.4.1s-.4-1-.1-1.4l5.4-5.8L.3 1.7C-.1 1.3-.1.7.4.3c.4-.4 1-.4 1.4.1l6 6.5c.1.1.2.3.2.6z"
    />
  </svg>
);

Chevron.propTypes = IconType;

export default Chevron;
