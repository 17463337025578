import { css } from "@emotion/react";

const defaults = css`
  position: relative;
  outline: thin dotted transparent;

  :before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    transition: clip-path 0.3s ease-out;
    clip-path: inset(100% 0 0 0);
  }

  :focus {
    outline-color: inherit;
  }

  :hover {
    &:before {
      transition: clip-path 0.2s ease-out;
      clip-path: inset(-1px 0 0 0);
    }
  }
`;

const toPrimary = (theme) => css`
  ${defaults};
  :hover {
    color: ${theme.brand("TdHWhite")};
  }
  :before {
    background: ${theme.brand("TdHRed")};
  }
`;

const toPrimaryBlack = (theme) => css`
  ${defaults};
  :hover {
    color: ${theme.brand("TdHBlack")};
  }
  :before {
    background: ${theme.brand("TdHRed")};
  }
`;

const toBlack = (theme) => css`
  ${defaults};
  :hover {
    color: ${theme.brand("TdHWhite")};
  }
  :before {
    background: ${theme.brand("TdHBlack")};
  }
`;

const toBrown = (theme) => css`
  ${defaults};
  :hover {
    color: ${theme.brand("TdHWhite")};
  }
  :before {
    background: ${theme.brand("TdHDarkBrown")};
  }
`;

export { toBlack, toBrown, toPrimary, toPrimaryBlack };
