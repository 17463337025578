import styled from "@emotion/styled";

const Overline = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.get("type.fontFamily.secondary")};
  font-weight: ${({ theme }) => theme.get("type.fontWeight.normal")};
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${({ theme, color }) => (color ? color : theme.brand("TdHBlack"))};
`;

export default Overline;
