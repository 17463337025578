import React from "react";

import { IconType } from "../index.types";

const Pinterest = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.28 14.28" {...props}>
    <path
      fill={color}
      d="M7.14,14.28A7,7,0,0,1,5,14a7.87,7.87,0,0,0,.88-1.87l.45-1.7A1.91,1.91,0,0,0,8,11.21c2.16,0,3.71-2,3.71-4.44A4.19,4.19,0,0,0,7.3,2.65C4.22,2.65,2.58,4.71,2.58,7A3.21,3.21,0,0,0,4,9.74c.13.06.2,0,.23-.1s.15-.58.2-.81a.2.2,0,0,0,0-.2A2.82,2.82,0,0,1,3.89,7,3.09,3.09,0,0,1,7.11,3.9a2.81,2.81,0,0,1,3,2.9c0,1.94-1,3.27-2.24,3.27A1,1,0,0,1,6.79,8.79a14.61,14.61,0,0,0,.59-2.38.9.9,0,0,0-.9-1c-.72,0-1.29.74-1.29,1.73A2.57,2.57,0,0,0,5.4,8.19s-.71,3-.84,3.55a7.06,7.06,0,0,0,0,2,7.14,7.14,0,1,1,2.6.49Z"
    />
  </svg>
);

Pinterest.propTypes = IconType;

export default Pinterest;
