import styled from "@emotion/styled";

const Description = styled.span`
  margin: 0;
  font-family: ${({ theme }) => theme.get("type.fontFamily.secondary")};
  font-weight: ${({ theme }) => theme.get("type.fontWeight.light")};
  font-size: 0.875rem;
  line-height: 1.2;
  color: ${({ theme, color }) => (color ? color : theme.brand("TdHBlack"))};
`;

export default Description;
