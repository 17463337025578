import { string } from "prop-types";
import React from "react";

const ArrowBack = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.03 9.07" {...props}>
    <path
      fill={color}
      d="M0.3,3.8l3.5-3.5c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L3.4,3.5H17c0.6,0,1,0.4,1,1s-0.4,1-1,1H3.4l1.8,1.8c0,0,0,0,0,0c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0L0.3,5.2C-0.1,4.8-0.1,4.2,0.3,3.8z"
    />
  </svg>
);

ArrowBack.propTypes = {
  color: string,
};

export default ArrowBack;
