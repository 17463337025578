import React from "react";

import { IconType } from "../index.types";

const StuffIcon = ({ color = "currentColor", ...props }) => (
  <svg
    viewBox="0 0 103 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M101.239 20.7451L81.0231 2.48778L70.8177 0.5L67.5623 6.19687C65.3729 9.97078 61.8655 12.8732 57.6738 14.3713C53.475 15.8693 48.8656 15.8693 44.674 14.3713C42.5782 13.6223 40.648 12.5203 38.9699 11.1375C37.2991 9.75472 35.873 8.08383 34.7783 6.19687L31.4869 0.5L21.2599 2.52379L1.06521 20.7451C0.582664 21.1701 0.287366 21.7678 0.265759 22.4088C0.236951 23.0426 0.467436 23.662 0.913967 24.1301L13.1504 37.1444C13.6113 37.6557 14.2883 37.951 14.9941 37.951C15.6999 37.951 16.3697 37.6557 16.8378 37.1444L23.7447 30.0719V83.1155C23.7447 83.7565 24.0111 84.3686 24.4865 84.8224C24.9618 85.2689 25.6028 85.5138 26.2654 85.4994H76.1257C76.7811 85.4994 77.4076 85.2473 77.8686 84.8008C78.3295 84.3542 78.5888 83.7493 78.5888 83.1155V30.0647L85.4956 37.13C85.9638 37.6413 86.6336 37.9366 87.3394 37.9366C88.0452 37.9366 88.715 37.6413 89.1831 37.13L101.391 24.1734C101.852 23.7052 102.096 23.0786 102.068 22.4304C102.039 21.7823 101.744 21.1773 101.239 20.7451ZM63.0034 45.4484C59.2871 45.4484 56.3414 42.2435 56.2694 38.2967C56.1758 33.4713 63.0034 26.1395 63.0034 26.1395C63.0034 26.1395 69.8238 33.4281 69.7446 38.2967C69.687 42.2435 66.7269 45.4484 63.0034 45.4484Z"
      fill={color}
    />
  </svg>
);

StuffIcon.propTypes = IconType;

export default StuffIcon;
