import Color from "color";

const darken = (color, amount) => Color(color).darken(amount).hex();

class Palette {
  darken = "0.2";

  black = {
    base: "#000000",
  };

  white = {
    base: "#FFFFFF",
    darken: darken("#ffffff", this.darken),
  };

  theme = {
    1: "#000032",
    2: "#00C8FF",
  };

  // old colors change to 'theme'
  primary = {
    1: "#00446c",
    2: "#f0f0f1",
  };

  secondary = {
    1: "#f0f0f1",
    2: "#dddee2",
  };

  text = {
    1: "#ffffff",
    2: "#000000",
    3: "#9a9da7",
  };

  textbutton = {
    base: ["#232231", "#E65140"],
    black: ["#232231", "#E65140"],
    white: ["#F9F9F9", "#E65140"],
    whiteAlt: ["#F9F9F9", "#232231"],
    primary: ["#E65140", "#232231"],
  };
}

export default new Palette();
