import Icon from "@components/atoms/Icon";
import Link from "@components/atoms/Link";
import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import theme from "@theme";
import { arrayOf, func, string } from "prop-types";
import React from "react";

import { ctaType } from "../../../__types__";

const StyledCTABlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
`;

const StyledCTA = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})(
  ({ theme }) => css`
    position: relative;
    display: inline-flex;
    padding: 0.5rem 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-decoration: none;
    border: 1px solid ${theme.brand("TdHLightBrown")};
    color: ${theme.brand("TdHLightBrown")};
    border-radius: 2rem;
    transition: all 0.3s ease-out;
    cursor: pointer;

    .inner-color,
    .outer-color {
      transition: all 0.3s ease-out;
    }

    .inner-color {
      fill: transparent;
    }

    &.cta-hand {
      display: none;
      @media (min-width: ${theme.get("breakpoints.lg")}) {
        display: inline-flex;
      }
    }

    :hover,
    &.active {
      color: ${theme.brand("TdHRed")};
      .inner-color {
        fill: ${theme.brand("TdHLightBrown")};
      }
      .outer-color {
        fill: ${theme.brand("TdHBlack")};
      }
    }

    &.primary {
      background: ${theme.brand("TdHRed")};
      border-color: ${theme.brand("TdHRed")};
      color: ${theme.brand("TdHWhite")};
      :hover,
      &.active {
        background: ${theme.brand("TdHBlack")};
        border-color: ${theme.brand("TdHBlack")};
        color: ${theme.brand("TdHWhite")};
        .inner-color {
          fill: transparent;
        }
        .outer-color {
          fill: ${theme.brand("TdHWhite")};
        }
      }
    }
  `
);

const StyledIcon = styled(Icon)`
  margin: 0 auto;
  display: block;
`;

const CTALabel = styled.span`
  font-weight: 600;
`;

const CallToActionBlock = ({ ctaItems, toggleMenu, currentPath }) => {
  return (
    <StyledCTABlock>
      {ctaItems.map(({ path, icon, label, trackEvent }, index) => (
        <StyledCTA
          key={path}
          to={path}
          onClick={() => {
            trackEvent && trackEvent("header");
            toggleMenu(false);
          }}
          className={`${currentPath.includes(path) ? "active" : ""} ${
            index === 0 ? "primary" : ""
          } ${`cta-${icon.toLowerCase()}`}`}
        >
          <StyledIcon color={theme.brand("TdHRed")} type={icon} size={1.5} />
          <CTALabel>{label}</CTALabel>
        </StyledCTA>
      ))}
    </StyledCTABlock>
  );
};

CallToActionBlock.propTypes = {
  ctaItems: arrayOf(ctaType),
  toggleMenu: func,
  trackEvent: func,
  currentPath: string,
};

export default CallToActionBlock;
