import Icon from "@components/atoms/Icon";
import ScreenreaderText from "@components/atoms/ScreenreaderText";
import styled from "@emotion/styled";
import React from "react";

import { socialBlockType } from "./index.types";

const StyledBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  color: ${({ color }) => color};
`;

const SocialItem = styled.a`
  color: currentColor;
  text-decoration: none;
`;

const SocialBlock = ({ socials, color = "black", size = 1.5, ...props }) => (
  <StyledBlock color={color} {...props}>
    {socials.map(
      ({ target, type }) =>
        target &&
        target !== "" && (
          <SocialItem
            key={target}
            href={target}
            size={size}
            title={type}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ScreenreaderText>{type}</ScreenreaderText>
            <Icon type={type} size={size} />
          </SocialItem>
        ),
    )}
  </StyledBlock>
);

SocialBlock.propTypes = socialBlockType;

export default SocialBlock;
