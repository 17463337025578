import { useTheme } from "@emotion/react";
import theme from "@theme";
import calcSize from "@utils/calcSize";
import { number, oneOf, string } from "prop-types";
import React from "react";

import icons from "./icons";

const Icon = ({
  type,
  size = 1,
  rotate,
  color,
  variant = "default",
  ...props
}) => {
  const theme = useTheme();
  const IconComp = icons[type];
  const width = calcSize(size);
  const height = calcSize(size);

  let rotateStyle = {};
  if (rotate) {
    rotateStyle = {
      transform: `rotate(${rotate}deg)`,
    };
  }

  const colorMap = {
    default: theme.brand("TdHBlack"),
    primary: theme.brand("TdHRed"),
    white: theme.brand("TdHWhite"),
  };

  return IconComp ? (
    <IconComp
      focusable="false"
      style={
        variant === "default"
          ? { ...rotateStyle }
          : { ...rotateStyle, color: color ? color : colorMap[variant] }
      }
      width={width}
      height={height}
      {...props}
    />
  ) : (
    process.env.NODE_ENV === "development" && (
      <span>Missing icon type: {type}</span>
    )
  );
};

Icon.propTypes = {
  type: oneOf(Object.keys(icons)).isRequired,
  size: oneOf(theme.get("sizes")),
  variant: oneOf(["default", "primary", "white"]),
  color: string,
  innerColor: string,
  rotate: number,
};

export default Icon;
