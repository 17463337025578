import styled from "@emotion/styled";
import { string } from "prop-types";
import React from "react";

const StyledLogo = styled.img`
  height: 2rem;
  max-width: 100%;
  @media (min-width: ${({ theme }) => theme.get("breakpoints.md")}) {
    height: 3.5rem;
  }
`;

const Logo = ({ className }) => (
  <StyledLogo
    className={className}
    src="/images/tdh_logo_black.svg"
    alt="Terre des Hommes logo"
    width="300"
    height="91"
  />
);

Logo.propTypes = {
  className: string,
};

export default Logo;
