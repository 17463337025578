import { Link } from "gatsby";
import React from "react";

import { InternalLinkType } from "../index.types";
import { stripLink } from "../utils";

const InternalLink = ({
  hostname,
  to,
  className,
  activeClassName,
  partiallyActive,
  children,
  ...other
}) => {
  // eslint-disable-next-line no-unused-vars
  const { primary, heavy, active, showMenu, white, ...props } = other;
  return (
    <Link
      to={stripLink(hostname, to)}
      className={className}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...props}
    >
      {children}
    </Link>
  );
};

InternalLink.propTypes = InternalLinkType;

export default InternalLink;
