import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Label = styled.p`
  font-family: ${({ theme }) => theme.get("type.fontFamily.secondary")};
  font-weight: ${({ theme }) => theme.get("type.fontWeight.black")};
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme, primary, white }) =>
    primary || white
      ? theme.brand(primary ? "TdHRed" : "TdHWhite")
      : theme.brand("TdHBlack")};
  margin: 0;
`;

Label.propTypes = {
  primary: PropTypes.bool,
  white: PropTypes.bool,
};

export default Label;
