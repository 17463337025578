import { string } from "prop-types";
import React from "react";

const BottleAndCan = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 708.7 708.7" {...props}>
    <path
      fill={color}
      d="M345.9,472.4c0-4.3,0.6-8.2,1.9-12.1l5.4-16.4v-21c0-0.1-0.1-0.3-0.1-0.6c0-0.6,0.2-1.6,1.2-2.6
		c3.6-3.7,19.4-6.3,38.2-7.7c0.3-50.7-5.4-95.2-24.8-113.9c-27.5-29.8-34.2-51-36-81.7V52c0-19.5-12.6-22-12.6-22h-43.6
		c0,0-12.6,2.5-12.6,22v164.3c-1.8,30.7-5.6,48.2-36,81.7c-47.7,51.1-17.8,260.4-7.7,324c3.4,21.7,58.7,26.4,78.2,26.4
		c10.5,0,32-1.4,49.8-6.1c-0.9-1.5-1.2-3-1.2-4.1V472.4z"
    />
    <path
      fill={color}
      d="M507.9,637.1c0,0.8-0.2,1.5-0.5,2.3l0,0l-11.4,29c0,0,0,0,0,0s-0.1,0.2-0.2,0.5l0,0.1l0,0c-0.2,0.4-0.5,1-1,1.5
	c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c-4.7,4.3-32.3,7.8-65.7,8.1c-36.2,0.4-65.7-3.2-66.6-7.9l0,0l-10.6-30.3l0.1,0
	c-0.8-1-1.2-2.2-1.2-3.4V473c0-3.9,0.6-7.6,1.8-11.2l5.6-16.6v-21.3c0-0.1-0.1-0.2-0.1-0.4c-0.1-4.8,31.6-9.1,70.6-9.4
	c33.3-0.4,61.3,2.2,68.8,6c2.9,1.2,3.6,2.5,3.6,3.9h0v21.9l5.3,15.9c1.2,3.6,1.8,7.4,1.8,11.2L507.9,637.1z M368.9,430.2
	c0,4.8,27.5,8.5,61.2,8.1c33.8-0.3,61.1-4.5,61.1-9.4c0-4.8-27.5-8.5-61.2-8.1C396.2,421.1,368.8,425.3,368.9,430.2z M480,648.7
	c-25.9,5.3-50.6,5.1-50.6,5.1c-12.9,0.1-24.9-0.4-34.3-1.4c-6.9-0.7-12-1.6-16.3-2.3c0,0-4.5-1.1-6.2-1.6c-2.3-0.7-6.4-2.2-8-3.7
	l1,4.4l0.6,2.7l0.9,4l1.8,7.9c0.1,4.4,29.5,7.7,60.5,7.4s60.8-4.1,60.7-8.6l5.4-18.7C494.8,645.1,483.2,647.6,480,648.7z"
    />
  </svg>
);

BottleAndCan.propTypes = {
  color: string,
};

export default BottleAndCan;
