import { SOCIALS } from "@constants/icons";
import { arrayOf, oneOf, shape, string } from "prop-types";

const sizes = [1, 1.5, 2];

export const socialType = shape({
  type: oneOf(Object.values(SOCIALS)).isRequired,
  target: string.isRequired,
});

export const socialBlockType = {
  socials: arrayOf(socialType).isRequired,
  color: string,
  size: oneOf(sizes),
};
