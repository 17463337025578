import React from "react";

import { IconType } from "../index.types";

const Search = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" {...props}>
    <path
      fill={color}
      d="M10.16,18A7.82,7.82,0,1,1,18,10.16,7.82,7.82,0,0,1,10.16,18Zm13.56,6.86,1.11-1.11a.59.59,0,0,0,0-.83L18.9,17a.54.54,0,0,0-.41-.17h-.65a10.14,10.14,0,1,0-1,1v.65a.58.58,0,0,0,.17.41l5.92,5.93A.59.59,0,0,0,23.72,24.83Z"
    />
  </svg>
);

Search.propTypes = IconType;

export default Search;
