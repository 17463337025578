import { any, array, bool, node, oneOfType, string } from "prop-types";

const LinkType = {
  to: string,
  children: oneOfType([string, node, array]).isRequired,
  other: any,
  className: string,
};

const DownloadLinkType = {
  ...LinkType,
  download: string,
};

const InternalLinkType = {
  ...LinkType,
  hostname: string,
  activeClassName: string,
  partiallyActive: bool,
};

export { DownloadLinkType, InternalLinkType, LinkType };
