import React from "react";

import { IconType } from "../index.types";

const Icon = ({ color = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 63 57"
    {...props}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M59.45.253a.75.75 0 00-.652-.248c-4.225.513-7.352 2.044-9.63 4.178-2.27 2.129-3.651 4.816-4.464 7.575-1.234 4.194-1.173 8.627-.871 11.731-.654-1.88-1.584-3.663-3.04-5.265-2.723-2.996-7.181-5.252-14.759-6.507a.75.75 0 00-.872.757c.068 2.996.476 7.916 3.099 11.756 1.324 1.938 3.21 3.597 5.872 4.585 2.604.967 5.905 1.276 10.079.639v4.296a.75.75 0 001.5 0V26.4c9.496-2.433 14.082-7.599 15.716-12.889C63.072 8.184 61.69 2.787 59.45.253zm.544 12.816c-1.418 4.595-5.374 9.308-13.97 11.696.278-.553.66-1.163 1.126-1.84.405-.59.849-1.197 1.32-1.841h.001c.326-.446.665-.91 1.015-1.398 1.674-2.344 3.455-5.108 4.242-8.254a.75.75 0 10-1.455-.364c-.714 2.854-2.349 5.423-4.008 7.746-.325.455-.656.908-.98 1.351h-.001a82.412 82.412 0 00-1.37 1.91c-.218.316-.425.629-.619.939-.262-2.96-.273-7.023.848-10.832.758-2.576 2.024-5.004 4.051-6.904 1.951-1.829 4.648-3.207 8.38-3.73 1.711 2.223 2.866 6.84 1.42 11.52zM29.5 23.384c-2.127-3.114-2.67-7.105-2.806-10.03 6.838 1.25 10.684 3.342 12.99 5.879 2.242 2.467 3.12 5.448 3.77 8.815-3.729.503-6.594.18-8.798-.64-2.344-.87-3.99-2.317-5.156-4.024zm23.596 10.954l-.008.006-.007.005-11.9 8.986H29.344a.351.351 0 01-.089-.125c-.011-.027-.013-.043-.013-.045 0-.023.01-.075.07-.136a.28.28 0 01.04-.034h8.025c2.22 0 4.412-1.624 4.858-4.046l.005-.026.004-.027c.439-2.978-1.783-5.58-4.764-5.58H21.079c-2.989 0-6.202 1.045-8.642 3.056l-.006.005-.006.005-4.284 3.603-5.076-.094h-.027c-1.9 0-3.038 1.56-3.038 3.17v10.02c0 1.677 1.302 3.169 3.038 3.169h35.467c2.56 0 5.138-.824 7.16-2.392l13.91-10.31a5.656 5.656 0 001.372-7.93 5.557 5.557 0 00-7.852-1.28zM29.279 44.835h12.404l12.3-9.288a4.057 4.057 0 015.74.939A4.157 4.157 0 0158.7 42.33L44.758 52.663c-1.743 1.356-3.998 2.087-6.253 2.087H3.038c-.82 0-1.538-.73-1.538-1.67V43.061c0-.94.615-1.67 1.538-1.67l5.637.105 4.716-3.966c2.152-1.775 5.023-2.714 7.688-2.714h16.4c2.051 0 3.589 1.774 3.281 3.862-.307 1.67-1.845 2.818-3.383 2.818H29.28c-.717 0-1.537.73-1.537 1.67 0 .834.717 1.67 1.537 1.67z"
      clipRule="evenodd"
    ></path>
  </svg>
);

Icon.propTypes = IconType;

export default Icon;
