import { string } from "prop-types";
import React from "react";

const At = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.56 13.56" {...props}>
    <path
      fill={color}
      d="M6.07,8.79c-.78,0-1.24-.54-1.24-1.43a2.35,2.35,0,0,1,2-2.55c.82,0,1.24.59,1.24,1.41C8.11,7.5,7.21,8.79,6.07,8.79Zm.71,4.77a6.75,6.75,0,0,0,4-1.32.33.33,0,0,0,.06-.47l-.42-.51a.32.32,0,0,0-.44,0,5.47,5.47,0,1,1-3.22-9.9c3,0,5.47,1.78,5.47,4.81A2.45,2.45,0,0,1,10,8.81c-.53,0-.55-.34-.45-.86l.79-4.06A.34.34,0,0,0,10,3.5H8.92a.34.34,0,0,0-.32.27,2.32,2.32,0,0,0-.06.38A2,2,0,0,0,6.75,3.3,3.91,3.91,0,0,0,3.06,7.48a2.47,2.47,0,0,0,2.55,2.79A2.81,2.81,0,0,0,7.79,9.12c.11.84.78,1,1.62,1a3.81,3.81,0,0,0,4.15-4c0-3.72-3-6.12-6.78-6.12a6.78,6.78,0,0,0,0,13.56Z"
    />
  </svg>
);

At.propTypes = {
  color: string,
};

export default At;
