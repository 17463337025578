import React from "react";

import { IconType } from "../index.types";

const Share = ({ color = "currentColor", ...props }) => (
  <svg viewBox="0 0 507.45 507.45" {...props}>
    <path
      fill={color}
      d="M408,178.5c-20.4,0-38.25,7.65-51,20.4L175.95,94.35c2.55-5.1,2.55-12.75,2.55-17.85C178.5,33.15,145.35,0,102,0    S25.5,33.15,25.5,76.5S58.65,153,102,153c20.4,0,38.25-7.65,51-20.4l181.05,104.55c-2.55,5.1-2.55,12.75-2.55,17.85    c0,5.1,0,12.75,2.55,17.85L153,379.95c-12.75-12.75-30.6-20.4-51-20.4c-40.8,0-73.95,33.15-73.95,73.95S61.2,507.45,102,507.45    s73.95-33.15,73.95-73.95c0-5.1,0-10.2-2.55-17.85L354.45,308.55c12.75,12.75,30.6,20.4,51,20.4c43.35,0,76.5-33.15,76.5-76.5    C481.95,209.1,451.35,178.5,408,178.5z"
    />
  </svg>
);

Share.propTypes = IconType;

export default Share;
