import { string } from "prop-types";
import React from "react";

const Instagram = ({ color = "currentColor", ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.18 14.18" {...props}>
    <path
      fill={color}
      d="M14.13,10A4.26,4.26,0,0,1,13,13a4.25,4.25,0,0,1-3,1.15c-1.17.06-4.67.06-5.85,0a4.26,4.26,0,0,1-3-1.15A4.18,4.18,0,0,1,.05,10C0,8.84,0,5.33.05,4.16a4.24,4.24,0,0,1,1.14-3,4.21,4.21,0,0,1,3-1.14C5.34,0,8.84,0,10,.05a4.29,4.29,0,0,1,3,1.14,4.2,4.2,0,0,1,1.14,3C14.2,5.34,14.2,8.84,14.13,10ZM12.9,7.09a15.63,15.63,0,0,0-.28-4.18,2.42,2.42,0,0,0-1.35-1.35,15.63,15.63,0,0,0-4.18-.28,15.66,15.66,0,0,0-4.18.28A2.37,2.37,0,0,0,1.57,2.91a15.63,15.63,0,0,0-.29,4.18,15.26,15.26,0,0,0,.29,4.18,2.37,2.37,0,0,0,1.34,1.35,16.06,16.06,0,0,0,4.18.28,15.63,15.63,0,0,0,4.18-.28,2.42,2.42,0,0,0,1.35-1.35A15.63,15.63,0,0,0,12.9,7.09Zm-2.17,0A3.64,3.64,0,1,1,7.09,3.45,3.63,3.63,0,0,1,10.73,7.09Zm-1.28,0A2.36,2.36,0,1,0,7.09,9.45,2.36,2.36,0,0,0,9.45,7.09Zm1.43-2.94A.84.84,0,0,1,10,3.31a.85.85,0,1,1,1.69,0A.84.84,0,0,1,10.88,4.15Z"
    />
  </svg>
);

Instagram.propTypes = {
  color: string,
};

export default Instagram;
