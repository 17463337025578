class I18n {
  constructor(translations = { en: [], nl: [] }, locale = "nl") {
    this.translations = Object.keys(translations).reduce((acc, _locale) => {
      acc[_locale] = acc[_locale] || {};
      for (const { key, value } of translations[_locale]) {
        acc[_locale][key] = value;
      }
      return acc;
    }, {});

    this.locale = locale;
  }

  text(key, defaultValue = "") {
    return this?.translations?.[this.locale]?.[key] || defaultValue;
  }

  setLocale(locale = "nl") {
    this.locale = locale;
  }
}

export default I18n;
