const getUserLang = (defaultLanguage = "nl") => {
  if (typeof window === "undefined" || !window.localStorage) {
    return defaultLanguage;
  }
  const languagePreference = window.localStorage.getItem("__gb_lng_pref");

  if (languagePreference) {
    return languagePreference;
  }

  const [browserLocale] = (navigator.language || navigator.userLanguage).split(
    "-",
  );

  return ["nl", "en"].includes(browserLocale) ? browserLocale : defaultLanguage;
};
export default getUserLang;
